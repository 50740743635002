import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { searchPremiers } from '../../redux/actions';
import { resultsSelector } from '../../redux/selectors';
import { isLoggedInSelector } from '../../../../redux/selectors';
import { addFavoritePremier, removeFavoritePremier } from '../../../Account/redux/actions';

import { showAuthenticationModal } from '../../../Modal/redux/actions';
import SearchPremiers from './SearchPremiers';

const mapStateToProps = state => {
  return {
    breedInformation: state.breedInformation,
    isLoggedIn: isLoggedInSelector(state),
    isMobile: state.browser.mediaType.indexOf('mobile') === 0,
    loaded: state.premiers.searchLoaded,
    loading: state.premiers.searchLoading,
    matchedPlace: state.premiers.matchedPlace,
    results: resultsSelector(state),
    total: state.premiers.searchTotal,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      addFavoritePremier,
      removeFavoritePremier,
      searchPremiers,
      showAuthenticationModal,
    },
    dispatch
  ),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPremiers);
