import React, { Component } from 'react';
import { Field, withFormik } from 'formik';
import debounce from 'lodash/debounce';
import { connect as felaConnect } from 'react-fela';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

import { Autocomplete, Button, Dropdown, GeoComplete } from '../../../../common/components';
import {
  resetBreedsAutocomplete,
  resetPremiersAutocomplete,
  updateBreedsAutocomplete,
  updatePremiersAutocomplete,
} from '../../../../redux/actions';
import { selectBreedsAutocomplete, selectPremiersAutocomplete } from '../../../../redux/selectors';

import { DISTANCE_OPTIONS } from '../../common/search-config';
class SearchPremiersForm extends Component {
  updateBreedAutocomplete = debounce(value => {
    if (value) {
      this.props.dispatch(updateBreedsAutocomplete(value));
    } else {
      this.props.dispatch(resetBreedsAutocomplete());
    }
  }, 250);

  updatePremierAutocomplete = debounce(value => {
    if (value) {
      this.props.dispatch(updatePremiersAutocomplete(value));
    } else {
      this.props.dispatch(resetPremiersAutocomplete());
    }
  }, 250);

  handleBreedInputChange = value => {
    if (!value) {
      this.props.setFieldValue('breed', '');
    }
    this.props.setFieldValue('formatted_breed', value || '');
    this.updateBreedAutocomplete(value);
  };

  handlePremierInputChange = value => {
    this.props.setFieldValue('name', value);
    this.updatePremierAutocomplete(value);
  };

  renderLocationAutoComplete = () => {
    const { styles } = this.props;
    return (
      <div className={styles.location}>
        <Field
          label="Location"
          insetLabel
          name="location"
          component={GeoComplete}
          placeholder="Showing all locations"
          initialValue={this.props.values.formatted_location}
        />
      </div>
    );
  };

  componentWillUnmount() {
    this.props.dispatch(resetBreedsAutocomplete());
    this.props.dispatch(resetPremiersAutocomplete());
  }

  renderDistance = () => {
    const { values, styles } = this.props;
    return (
      <div className={styles.distance}>
        <Field
          name="distance"
          component={Dropdown}
          options={DISTANCE_OPTIONS}
          variant="secondary"
          disabled={!values.formatted_location}
        />
      </div>
    );
  };

  onBreedSelect = (value = {}) => {
    this.props.dispatch(resetBreedsAutocomplete());
    this.props.setFieldValue('formatted_breed', value.label);
    this.props.setFieldValue('breed', value.id);
  };

  onPremierSelect = value => {
    this.props.dispatch(resetPremiersAutocomplete());
    this.props.setFieldValue('name', value.label);
  };

  renderBreedsAvailableAutoComplete = () => {
    const { styles } = this.props;
    return (
      <div className={styles.breedsAvailable}>
        <Field
          label="Breed"
          insetLabel
          name="breed"
          component={Autocomplete}
          items={this.props.breeds}
          disabled={false}
          onInputChange={this.handleBreedInputChange}
          initialValue={this.props.values.formatted_breed}
          placeholder="Type a breed"
          onSelect={this.onBreedSelect}
        />
      </div>
    );
  };

  renderPremiersAvailableAutoComplete = () => {
    const { styles } = this.props;
    return (
      <div className={styles.premiersAvailable}>
        <Field
          label="Keyword"
          insetLabel
          name="name"
          component={Autocomplete}
          items={this.props.premiers}
          disabled={false}
          onInputChange={this.handlePremierInputChange}
          initialValue={this.props.values.name}
          placeholder="Example: Doug's Dalmatians"
          onSelect={this.onPremierSelect}
        />
      </div>
    );
  };

  render() {
    const { handleSubmit, styles } = this.props;
    return (
      <form id="search-premier-users-form" onSubmit={handleSubmit}>
        <div className={styles.formWrapper}>
          <div className={styles.form}>
            <div className={styles.formLeft}>
              {this.renderLocationAutoComplete()}
              {this.renderDistance()}
            </div>
            <div className={styles.formRight}>
              {this.renderBreedsAvailableAutoComplete()}
              {this.renderPremiersAvailableAutoComplete()}
            </div>
            <div className={styles.button}>
              <Button type="submit">Search</Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const FormikSearchPremiersForm = withFormik({
  mapPropsToValues: props => {
    const { query } = props;
    return {
      city: query.city,
      state: query.state,
      formatted_location: query.formatted_location,
      distance: query.distance,
      location: query.formatted_location,
      breed: query.breed,
      formatted_breed: query.formatted_breed,
      premier: query.premier,
      name: query.name,
    };
  },
  validateOnChange: false,
  validationSchema: yup.object().shape({
    location: yup.mixed(),
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(false);
    const search = { ...values };
    if (search.breed && search.formatted_breed) {
      const autocompleteBreed = props.breeds.find(breed => breed.id === search.breed);
      search.formatted_breed = autocompleteBreed ? autocompleteBreed.label : search.formatted_breed;
    }

    // clear location values
    if (!values.location) {
      search.city = null;
      search.formatted_location = null;
      search.state = null;
    } else {
      delete search.location;
    }

    props.onSubmit(search);
  },
})(SearchPremiersForm);

const styles = props => ({
  formWrapper: {
    backgroundColor: props.theme.colors.darkerTan,
    padding: '24px 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: props.theme.globalMaxWidth,
    ...props.theme.globalPadding,
    margin: '0 auto',
    alignItems: 'center',
    '> * + *': {
      marginTop: '16px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '18px',
      },
    },
  },
  formLeft: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '10px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      width: 'inherit',
      '> * + *': {
        marginLeft: '13px',
      },
    },
  },
  formRight: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '15px',
    },
    [props.theme.breakpoints.tabletLarge]: {
      width: 'inherit',
      '> * + *': {
        marginLeft: '20px',
      },
    },
  },
  location: {
    width: '75%',
    [props.theme.breakpoints.tabletLarge]: {
      width: '170px',
    },
  },
  distance: {
    width: '25%',
    [props.theme.breakpoints.tabletLarge]: {
      minWidth: '90px',
    },
  },
  breedsAvailable: {
    width: '100%',
    [props.theme.breakpoints.tabletLarge]: {
      width: '306px',
    },
  },
  premiersAvailable: {
    width: '100%',
    [props.theme.breakpoints.tabletLarge]: {
      width: '286px',
    },
  },
  button: {
    width: '100%',
    '>button': {
      width: '100%',
    },
    [props.theme.breakpoints.tabletLarge]: {
      width: 'inherit',
    },
  },
});

const mapStateToProps = state => ({
  breeds: selectBreedsAutocomplete(state),
  premiers: selectPremiersAutocomplete(state),
});

export default withRouter(connect(mapStateToProps)(felaConnect(styles)(FormikSearchPremiersForm)));
