import React from 'react';
import PropTypes from 'prop-types';

import { Loading, PremierListHeader } from '../../../../common/components';

import SearchPremiersPager from './SearchPremiersPager';
import SearchPremiersTable from './SearchPremiersTable';

const SearchPremiersResults = ({
  addFavoritePremier,
  breedLabel,
  handlePaginate,
  isMobile,
  loaded,
  removeFavoritePremier,
  results,
  total,
  formattedLocation,
}) => {
  if (!loaded) {
    return <Loading dark center />;
  }

  return (
    <div>
      <PremierListHeader breedLabel={breedLabel} total={total} formattedLocation={formattedLocation} />
      <SearchPremiersTable
        addFavoritePremier={addFavoritePremier}
        isMobile={isMobile}
        loaded={loaded}
        removeFavoritePremier={removeFavoritePremier}
        results={results}
        total={total}
      />
      <SearchPremiersPager handlePaginate={handlePaginate} total={total} />
    </div>
  );
};

SearchPremiersResults.propTypes = {
  addFavoritePremier: PropTypes.func,
  breedLabel: PropTypes.string,
  handlePaginate: PropTypes.func,
  isMobile: PropTypes.bool,
  loaded: PropTypes.bool,
  removeFavoritePremier: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
};

export default SearchPremiersResults;
