export const DISTANCE_OPTIONS = [
  {
    value: 25,
    label: '25 mi',
  },
  {
    value: 50,
    label: '50 mi',
  },
  {
    value: 100,
    label: '100 mi',
  },
  {
    value: 250,
    label: '250 mi',
  },
  {
    value: 500,
    label: '500 mi',
  },
  {
    value: 1000,
    label: '1000 mi',
  },
  {
    value: 20000,
    label: '1000+ mi',
  },
];

export const SORT_OPTIONS = [
  {
    value: 'default',
    label: 'Sort By...',
    query: {
      sort_by: 'location',
      sort_dir: 'asc',
    },
  },
  {
    value: 'mostExpensive',
    label: 'Most Expensive',
    query: {
      sort_by: 'price',
      sort_dir: 'desc',
    },
  },
  {
    value: 'leastExpensive',
    label: 'Least Expensive',
    query: {
      sort_by: 'price',
      sort_dir: 'asc',
    },
  },
  {
    value: 'youngest',
    label: 'Youngest',
    query: {
      sort_by: 'birthdate',
      sort_dir: 'desc',
    },
  },
  {
    value: 'oldest',
    label: 'Oldest',
    query: {
      sort_by: 'birthdate',
      sort_dir: 'asc',
    },
  },
  {
    value: 'closest',
    label: 'Closest',
    query: {
      sort_by: 'location',
      sort_dir: 'asc',
    },
  },
];

export const defaults = {
  distance: 1000,
  breed: '',
  city: '',
  formatted_breed: '',
  formatted_location: null,
  name: '',
  page: 0,
  page_size: 16,
  premier: '',
  sort: 'default',
  sort_by: 'location',
  sort_dir: 'asc',
  state: '',
};

const getLocationParts = (query, user) => {
  // use query location if present
  if (query.formatted_location) {
    return {
      city: query.city,
      formatted_location: query.formatted_location,
      state: query.state,
    };
  }

  // TODO: figure out how to get this to work with the constant redirects
  // fall back to user location if signed in and `formatted_location` is present
  // if (user?.get('formatted_location')) {
  //   const formattedLocation = user?.get('formatted_location');
  //   const [city, state] = formattedLocation?.split(', ');
  //   return {
  //     city: city.toLowerCase(),
  //     formatted_location: formattedLocation,
  //     state: state.toUpperCase(),
  //   };
  // }

  return {
    city: '',
    formatted_location: null,
    state: '',
  };
};

export const mapQueryToFormValues = (query, user) => {
  const formValues = { ...defaults, ...query };

  formValues.distance = parseInt(formValues.distance, 10);

  const { city, formatted_location, state } = getLocationParts(query, user);
  formValues.city = city;
  formValues.formatted_location = formatted_location;
  formValues.state = state;

  const matchedSortOption = SORT_OPTIONS.find(
    option => option.query.sort_by === query.sort_by && option.query.sort_dir === query.sort_dir
  );
  formValues.sort = matchedSortOption ? matchedSortOption.value : 'default';

  return formValues;
};

export const mapFormValuesToQuery = values => {
  const matchedSortOption = SORT_OPTIONS.find(option => option.value === values.sort);
  const query = { ...values, ...matchedSortOption.query };
  delete query.gclid;
  query.distance = `${values.distance}mi`;

  if (!values.breed) {
    delete query.breed;
    delete query.formatted_breed;
  }

  if (!values.city) {
    delete query.city;
  }

  if (!values.formatted_location) {
    delete query.formatted_location;
  }

  if (!values.name) {
    delete query.name;
  }

  if (!values.premier) {
    delete query.premier;
  }

  if (!values.state) {
    delete query.state;
  }

  return query;
};
