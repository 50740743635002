import React from 'react';
import PropTypes from 'prop-types';

import { NoContentPlaceholder, Table, Tbody, Td, Th, Thead, Tr } from '../../../../common/components';

import AdBanner from '../../../../common/components/AdBanner';

import SearchPremiersRow from './SearchPremiersRow';

import { ColumnHeading, TableContainer } from './styled';

const SearchPremiersTable = ({
  addFavoritePremier,
  isMobile,
  loaded,
  removeFavoritePremier,
  results = [],
  total = 0,
}) => {
  if (loaded && !total) {
    return (
      <NoContentPlaceholder icon="PuppyOutline" title="Nothing found! Try searching for something less specific." />
    );
  }

  const beforeAd = results.slice(0, 3);
  const afterAd = results.slice(3);

  return (
    <TableContainer>
      <Table>
        {!isMobile && (
          <Thead>
            <Tr transparent>
              <ColumnHeading>Name and Location</ColumnHeading>
              <Th>Breeds Available</Th>
              <Th></Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {beforeAd.map(result => (
            <SearchPremiersRow
              key={result.user_id}
              addFavoritePremier={addFavoritePremier}
              isMobile={isMobile}
              premier={result}
              removeFavoritePremier={removeFavoritePremier}
            />
          ))}
          <Tr>
            <Td colSpan={3}>
              <AdBanner placementName="puppies_leaderboard_btf1" slotId="premiers-search" />
            </Td>
          </Tr>
          {afterAd.map(result => (
            <SearchPremiersRow
              key={result.user_id}
              addFavoritePremier={addFavoritePremier}
              isMobile={isMobile}
              premier={result}
              removeFavoritePremier={removeFavoritePremier}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

SearchPremiersTable.propTypes = {
  addFavoritePremier: PropTypes.func,
  isMobile: PropTypes.bool,
  loaded: PropTypes.bool,
  removeFavoritePremier: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
};

export default SearchPremiersTable;
