import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import querystring from 'query-string';
import UserAuthenticationLayout from '../../layouts/UserAuthenticationLayout';

import LoginForm from './LoginForm';

class Login extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
  };

  render() {
    const { isLoggedIn, login, setErrorBarMessage, clearErrorBar, user, location, userVM } = this.props;
    if (isLoggedIn && user) {
      window.freestar?.queue.push(function() {
        window.freestar?.setVideoAdhesionEnabled(false);
        window.freestar?.deleteStickyFooter();
      });
      const parsedSearch = querystring.parse(location.search);
      if (parsedSearch.from) {
        return <Redirect to={parsedSearch.from} />;
      } else if (userVM.get('isActive')) {
        return <Redirect to="/listings" />;
      } else {
        return <Redirect to="/find-a-puppy" />;
      }
    }

    return (
      <UserAuthenticationLayout title="Sign in to continue" subTitle="Welcome back!" signIn>
        <LoginForm setError={setErrorBarMessage} clearError={clearErrorBar} submitAction={login} />
      </UserAuthenticationLayout>
    );
  }
}

export default connect(compose(withRouter))(Login);
