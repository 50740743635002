import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';

import Icon from '../icons/icon';
import HelpScout from '../HelpScout';

class Footer extends Component {
  render() {
    const { isLoggedIn, styles } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.footerInner}>
          <div className={styles.footerLeft}>
            <div className={styles.socialLinks}>
              <a
                aria-label="Visit us on Twitter"
                href="https://twitter.com/Puppies_dotcom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="Twitter" size={20} />
              </a>
              <a
                aria-label="Visit us on Instagram"
                href="https://www.instagram.com/realpuppiesdotcom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="Instagram" size={20} />
              </a>
              <a
                aria-label="Visit us on Facebook"
                href="https://www.facebook.com/realpuppiesdotcom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="Facebook" size={20} />
              </a>
            </div>
            <div className={styles.footerText}>© {new Date().getFullYear()} Puppies.com, LLC</div>
          </div>
          <div className={styles.footerLinks}>
            <div>
              <Link to="/find-a-breeder">Find A Featured Breeder</Link>
              <a href="https://www.puppiescares.org" target="_blank" rel="noreferrer">
                Puppies.com Cares
              </a>
              <a
                href="https://help.puppies.com/article/124-puppies-com-terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              <a
                href="https://help.puppies.com/article/128-puppies-com-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
              <button
                onClick={() => {
                  if (window.illow?.showWidget) {
                    window.illow.showWidget();
                  }
                }}
                className={styles.privacyButtonLink}
              >
                Privacy Control Center
              </button>
            </div>
            <div>
              {isLoggedIn ? <HelpScout /> : <Link to="/contact">Contact</Link>}
              <a href="https://help.puppies.com">FAQ</a>
              {isLoggedIn ? <Link to="/logout">Sign Out</Link> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  root: props => ({
    backgroundColor: props.theme.colors.blue,
    padding: '0 10px 114px',
    position: 'relative',
    color: 'white',
    [props.theme.breakpoints.desktop]: {
      fontSize: '12px',
    },
  }),
  privacyButtonLink: props => ({
    background: 'none !important',
    border: 'none',
    padding: '0!important',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    marginBottom: '1em',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'underline',
    },
    [props.theme.breakpoints.desktop]: {
      marginBottom: '0',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
    },
  }),
  footerInner: props => ({
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    '> div': {
      paddingTop: '20px',
    },
    [props.theme.breakpoints.desktop]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  footerLeft: props => ({
    [props.theme.breakpoints.desktop]: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
    },
  }),
  footerText: props => ({
    marginTop: '20px',
    [props.theme.breakpoints.desktop]: {
      marginTop: '0',
    },
  }),
  socialLinks: props => ({
    display: 'flex',
    gap: '0.5em',
  }),
  footerLinks: props => ({
    display: 'flex',
    gap: '2em',
    fontWeight: 'bold',
    '> div *': {
      display: 'block',
      marginBottom: '1em',
    },
    '> div > a:hover': {
      textDecoration: 'underline',
    },
    '> div > div:hover': {
      textDecoration: 'underline',
    },
    [props.theme.breakpoints.desktop]: {
      gap: '1em',
      fontWeight: 'normal',
      '> div': {
        display: 'flex',
        gap: '1em',
      },
      '> div *': {
        marginBottom: '0',
      },
    },
  }),
};

export default felaConnect(styles)(Footer);
