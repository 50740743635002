import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import ReactAutocomplete from '../Autocomplete/ReactAutocomplete';
import { InputScaffold } from '../';
import theme from '../../../theme';
import config from '../../config';

class GeoComplete extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      setFieldValue: PropTypes.func.isRequired,
      values: PropTypes.object.isRequired,
    }),
    label: PropTypes.node,
    insetLabel: PropTypes.bool,
    labelVariant: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    countries: PropTypes.array,
    maxResults: PropTypes.number,
    hasError: PropTypes.bool,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    countries: ['US', 'PR', 'GU', 'VI', 'AS', 'MP'],
    maxResults: 10,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
      items: [],
      isDropdownVisible: false,
    };
  }

  componentDidUpdate = prevProps => {
    const {
      field: { value, name },
      form: { setFieldValue },
    } = this.props;
    if (prevProps.field.value !== value && value === '') {
      setFieldValue(name, value);
      this.setState({ value }, this.updateResults);
    }
    if (value && value !== '' && value.formatted_location && value.formatted_location !== this.state.value) {
      this.setState({ value: value.formatted_location });
    }
  };

  updateResults = debounce(async () => {
    const { countries, maxResults } = this.props;
    const { value } = this.state;

    if (value) {
      try {
        const countryQuery = countries.length ? countries.map(country => `&country=${country}`).join('') : '';
        const response = await fetch(
          `https://geocode-api.vianet.us/places?q=${value}&size=${maxResults}${countryQuery}`,
          {
            headers: {
              Authorization: `Bearer ${config.geocodeApiToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Received error response from geocode API');
        }
        const json = await response.json();
        const places = {};

        // sort places by highest match score
        const sortedPlaces = json.sort((placeA, placeB) => {
          return placeB._score - placeA._score;
        });

        // keep track of unique places
        sortedPlaces.forEach(place => {
          const placeName =
            place._source.country_code === 'US'
              ? `${place._source.name}, ${place._source.admin1_code}`
              : `${place._source.name}, ${place._source.country_code}`;

          if (!places[placeName]) {
            places[placeName] = place;
          }
        });

        // create the list for the dropdown
        const items = Object.keys(places).map(key => {
          const place = places[key];
          return {
            label: key,
            id: place._id,
            zip: place._source.postal_code,
            location: {
              lat: parseFloat(place._source.location.lat),
              lng: parseFloat(place._source.location.lon),
            },
          };
        });

        this.setState({
          items,
        });
      } catch (err) {
        this.setState({ items: [] });
      }
    } else {
      this.setState({ items: [] });
    }
  }, 250);

  handleChange = e => {
    const {
      form: { setFieldValue, values },
      field: { name },
    } = this.props;

    setFieldValue(name, null);

    // reset the distance to 1000 if location is removed
    if (!e.target.value && values.distance !== undefined) {
      setFieldValue('distance', 1000);
    }

    this.setState({ value: e.target.value, isDropdownVisible: true }, this.updateResults);
  };

  handleSelect = (value, item) => {
    const {
      form: { setFieldValue, values },
      field: { name },
    } = this.props;

    this.setState({ value, isDropdownVisible: false }, () => {
      setFieldValue(name, item);

      if (values.hasOwnProperty('formatted_location')) {
        setFieldValue('formatted_location', value);
      }
    });
  };

  getItemValue = item => {
    return item.label + (item.zip ? ` ${item.zip}` : '');
  };

  renderItem = (item, highlighted) => {
    const { styles } = this.props;
    return (
      <div key={item.id} className={classNames([styles.renderItem, highlighted && styles.renderItemHighlighted])}>
        <span>{item.label}</span>
        {item.zip ? <span>&nbsp;{item.zip}</span> : null}
      </div>
    );
  };

  render() {
    const {
      field,
      form,
      label,
      labelVariant,
      disabled,
      insetLabel,
      placeholder,
      styles,
      required,
      hasError,
      openMenuUp,
      onBlur,
    } = this.props;
    const { items, isDropdownVisible } = this.state;
    const error = form.touched[field.name] && form.errors[field.name];

    const inputClasses = {
      [styles.root]: true,
      [styles.error]: error || hasError,
      [styles.disabled]: disabled,
    };

    return (
      <InputScaffold label={label} labelVariant={labelVariant} insetLabel={insetLabel} required={required}>
        <ReactAutocomplete
          selectOnBlur={false}
          items={items}
          getItemValue={this.getItemValue}
          renderItem={this.renderItem}
          value={this.state.value}
          onSelect={this.handleSelect}
          onChange={this.handleChange}
          open={isDropdownVisible}
          inputProps={{
            onBlur: e => {
              if (!field.value) {
                this.setState({ value: '' }, this.updateResults);
              }
              field.onBlur(e);
              if (onBlur) {
                onBlur(e);
              }
            },
            name: field.name,
            disabled: disabled,
            className: classNames(inputClasses),
            placeholder,
          }}
          wrapperStyle={{
            position: 'relative',
            width: '100%',
          }}
          menuStyle={{
            boxShadow: 'inset 0 1px 0 0 #9B9B9B, 0 2px 3px 0 rgba(0,0,0,0.4)',
            backgroundColor: '#FFFFFF',
            borderRadius: 2,
            position: 'absolute',
            left: 0,
            top: openMenuUp ? 'inherit' : 42,
            bottom: openMenuUp ? 42 : 'inherit',
            zIndex: 2,
            color: theme.colors.blue,
            fontSize: '11px !important',
            maxHeight: '160px',
            overflowY: 'scroll',
          }}
        />
      </InputScaffold>
    );
  }
}

const styles = props => ({
  root: {
    width: '100%',
    height: '42px',
    padding: props.insetLabel ? '18px 12px 8px' : '12px 14px',
    backgroundColor: props.theme.colors.white,
    outline: 'none',
    borderRadius: props.theme.globalBorderRadius,
    boxShadow: 'rgb(160, 160, 161) 0px 8px 1px -7px inset',
    border: `1px solid ${props.theme.colors.disabled}`,
    borderWidth: '1px',
    fontFamily: props.theme.typography.sans,
    fontSize: props.insetLabel ? '11px' : '14px',
    fontWeight: 'normal',
    color: props.theme.colors.textDark,
    '::placeholder': {
      color:
        (props.form.touched[props.field.name] && props.form.errors[props.field.name] && !props.field.value) ||
        props.hasError
          ? props.theme.colors.red
          : '#9C9C9C',
      fontWeight: 'normal',
      width: '100%',
    },
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: props.theme.colors.blue,
    },
  },
  error: props => ({
    color: props.theme.colors.red,
    border: `1px solid ${props.theme.colors.red} !important`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: `${props.theme.colors.red} !important`,
    },
  }),
  disabled: props => ({
    color: props.theme.colors.disabledText,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.disabled}`,
    backgroundColor: props.theme.colors.disabled,
    cursor: 'not-allowed',
  }),
  renderItem: {
    padding: '12px 14px',
    fontSize: '11px',
    textAlign: 'left',
  },
  renderItemHighlighted: {
    backgroundColor: props.theme.colors.blue,
    color: props.theme.colors.white,
  },
});

export default connect(styles)(GeoComplete);
