import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import * as yup from 'yup';
import { withFormik, Field } from 'formik';
import debounce from 'lodash/debounce';
import compose from 'lodash/flowRight';
import cloneDeep from 'lodash/cloneDeep';
import { Link, withRouter } from 'react-router-dom';
import { List } from 'immutable';
import { phoneNumberSchema, formatPhoneNumber } from '../../../../common/components/PhoneValidation';
import {
  Autocomplete,
  Chip,
  Button,
  InputError,
  StandaloneField,
  Textarea,
  TextInput,
  Modal,
  PlainLink,
  PhotoGalleryUploader,
  Tooltip,
  IconButton,
  LicensingRadioGroup,
  FormErrors,
} from '../../../../common/components';
import theme from '../../../../theme';
import PurchaseWizard from '../../common/components/PurchaseWizard';

const prependHttps = url => {
  const re = new RegExp('^(http|https)://', 'i');
  return re.test(url) ? url : 'https://' + url;
};

const MAX_BREEDS = 4;

const photoGalleryHeadingText = (
  <span>
    <strong>Facility photos.</strong> Show where and how your puppies and adult dogs live and play.
  </span>
);

class PremierProfileForm extends Component {
  state = {
    showPaymentModal: false,
    showWebsiteTooltip: false,
    showPremierSlugTooltip: false,
    showPhoneNumberChangedModal: false,
    successfulPayment: false,
    isLoading: false,
  };

  componentWillUnmount() {
    this.props.resetBreedsAutocomplete();
  }

  updateAutocomplete = debounce(value => {
    if (value) {
      this.props.updateBreedsAutocomplete(value);
    } else {
      this.props.resetBreedsAutocomplete();
    }
  }, 250);

  handleBreedSelect = breed => {
    const { setFieldValue, values, clearErrorBar, resetBreedsAutocomplete } = this.props;
    setFieldValue('breeds_available', values.breeds_available.concat(breed.id));
    setFieldValue('formatted_breeds', values.formatted_breeds.concat(breed.label));
    resetBreedsAutocomplete();
    clearErrorBar();
  };

  handleInputChange = value => {
    this.updateAutocomplete(value);
  };

  handleBreedRemove = id => {
    const { setFieldValue, values } = this.props;

    const index = values.breeds_available.findIndex(breed => breed === id);

    const remove = (arr, index) => {
      return arr.slice(0, index).concat(arr.slice(index + 1));
    };

    setFieldValue('breeds_available', remove(values.breeds_available, index));
    setFieldValue('formatted_breeds', remove(values.formatted_breeds, index));
  };

  renderBreedChips = () => {
    const { values } = this.props;
    return values.formatted_breeds.map((breed, i) => (
      <Chip id={values.breeds_available[i]} onRemove={this.handleBreedRemove} key={breed} variant="secondary">
        {breed}
      </Chip>
    ));
  };

  renderPaymentModal = () => {
    const {
      purchaseMembership,
      purchaseErrors,
      membershipPlans,
      getBraintreeToken,
      styles,
      user,
      location,
      userVM,
    } = this.props;
    return (
      <Modal closeOnEscape closeOnOutsideClick={false} isOpen className={styles.paymentModal}>
        <PurchaseWizard
          onSubmit={({ plan, nonce }) => purchaseMembership({ plan, payment_token: nonce })}
          onCancel={() => {
            this.setState({ showPaymentModal: false });
            if (this.state.successfulPayment) {
              return this.props.submitForm();
            }
            return this.props.history.push('/account/membership');
          }}
          getBraintreeToken={getBraintreeToken}
          plan={location.state.plan}
          errors={purchaseErrors}
          user={user}
          membershipPlans={membershipPlans}
          discountDuration={null}
          userVM={userVM}
          setAsSuccessfulPayment={() => this.setState({ successfulPayment: true })}
        />
      </Modal>
    );
  };

  handleChangeSlug = e => {
    const { setFieldValue } = this.props;
    setFieldValue('slug', e.target.value.toLowerCase().trim());
  };

  handleChangeWebsite = e => {
    const { setFieldValue } = this.props;
    setFieldValue(
      'website',
      e.target.value
        .toLowerCase()
        .replace('https://', '')
        .replace('http://', '')
        .trim()
    );
  };

  handleSubmitInteraction = async (values, setSubmitting, setFieldError) => {
    const {
      user,
      isPremierSlugAvailable,
      initialValues,
      validateForm,
      submitForm,
      fromMembership,
      checkPhoneBlacklist,
    } = this.props;
    const { showPhoneNumberChangedModal } = this.state;
    const validation = await validateForm();

    const slugAvailable = await checkSlugAvailability();
    if (!slugAvailable) {
      setSubmitting(false);
      return setFieldError('slug', 'Homepage name is already in use');
    }

    let blacklistedPhone = false;
    if (values.phone) {
      blacklistedPhone = await checkPhoneBlacklist(values.phone).then(action => action.json);
      if (blacklistedPhone === true) {
        setSubmitting(false);
        return setFieldError('phone', 'Phone number cannot be used');
      }
    }

    if (
      Object.keys(validation).length === 0 &&
      user.get('phone_number') !== values.phone &&
      user.get('phone_number_verified') &&
      !showPhoneNumberChangedModal
    ) {
      return this.setState({ showPhoneNumberChangedModal: true });
    } else if (Object.keys(validation).length === 0 && fromMembership) {
      return this.setState({ showPaymentModal: true });
    } else {
      return submitForm();
    }

    async function checkSlugAvailability() {
      let result = true;
      if (values.slug !== initialValues.slug) {
        result = await isPremierSlugAvailable(values.slug).then(action => action.json);
      }
      return result;
    }
  };

  handleDelete = ({ photoMap, sortIndex }) => {
    const { deletePremierProfileImage, setFieldValue, values } = this.props;

    if (photoMap) {
      deletePremierProfileImage(photoMap.get('key'), values.id);
    }

    const photos = values.photos.filter((_, index) => index !== sortIndex);
    setFieldValue('photos', photos);
  };

  handleEdit = (photo, sortIndex) => {
    const { setFieldValue, values } = this.props;

    const updatedPhotos = values.photos.map((p, index) => {
      if (index === sortIndex) {
        return {
          ...p,
          ...photo,
        };
      }

      return p;
    });

    setFieldValue('photos', updatedPhotos);
  };

  handleSort = ({ newIndex, oldIndex }) => {
    const { setFieldValue, values } = this.props;
    const updatedPhotos = [...values.photos];
    const element = updatedPhotos.splice(oldIndex, 1)[0];

    updatedPhotos.splice(newIndex, 0, element);

    setFieldValue('photos', updatedPhotos);
  };

  handleUpload = photo => {
    const { setFieldValue, values } = this.props;
    const photos = [...values.photos, photo];
    setFieldValue('photos', photos);
  };

  labelLinkRightClick = () => {
    const { slug } = this.props.values;
    navigator.clipboard.writeText(`https://puppies.com/premier/${slug}`);
    alert('Homepage link copied');
  };

  render() {
    const {
      handleBlur,
      isSubmitting,
      status,
      values,
      breeds,
      styles,
      touched,
      errors,
      setFieldError,
      setFieldValue,
      setSubmitting,
      initialValues,
      profile,
      user,
    } = this.props;
    const { showPremierSlugTooltip, showWebsiteTooltip } = this.state;

    const profileEstablished =
      initialValues.name && initialValues.phone && initialValues.slug && initialValues.facility_description;

    let buttonCopy = '';

    if (this.state.isLoading) {
      buttonCopy = 'UPLOADING IMAGES...';
    } else if (isSubmitting) {
      buttonCopy = 'SAVING...';
    } else if (status && status.success) {
      buttonCopy = 'CHANGES SAVED';
    } else if (!this.props.fromMembership) {
      buttonCopy = 'SAVE CHANGES';
    } else {
      buttonCopy = 'CONTINUE';
    }

    return (
      <form id="premier-profile-form" onSubmit={event => event.preventDefault()}>
        <PhotoGalleryUploader
          header={photoGalleryHeadingText}
          path={`${user?.get('id')}/facility`}
          photos={profile ? profile.get('photos') : new List()}
          userPhotos={values.photos}
          onDelete={this.handleDelete}
          onEdit={this.handleEdit}
          onSort={this.handleSort}
          onUpload={this.handleUpload}
        />
        <hr className={styles.rule} />
        <div>
          <h1 className={styles.title}>
            Premier Profile Information
            {profileEstablished && (
              <PlainLink className={styles.profileLink} variant="blue" to={`/premier/${initialValues.slug}`}>
                View Profile
              </PlainLink>
            )}
          </h1>
          <div className={styles.formWrapper}>
            <div className={styles.formTopRow}>
              <div className={styles.businessName}>
                <Field
                  label="Kennel/Breeder Name"
                  name="name"
                  component={TextInput}
                  placeholder="Example: West Valley Greyhounds"
                  required
                />
              </div>
              <div className={styles.businessWebsite}>
                <StandaloneField
                  label="Website"
                  name="website"
                  component={TextInput}
                  onChange={this.handleChangeWebsite}
                  value={values.website?.replace('https://', '')}
                  prefix="https://"
                  prefixPadding={60}
                  onBlur={handleBlur}
                  className={errors && touched.website && errors.website ? styles.inputError : null}
                />
                <div className={styles.websiteTip}>
                  <div
                    className={styles.websiteHover}
                    onTouchStart={() => {
                      if (!showWebsiteTooltip) {
                        this.setState({ showWebsiteTooltip: true });
                      }
                    }}
                    onMouseEnter={() => this.setState({ showWebsiteTooltip: true })}
                    onMouseLeave={() => this.setState({ showWebsiteTooltip: false })}
                  >
                    <span className={styles.websiteText}>What's this?</span>
                    <IconButton icon={'Info'} variant={'orange'} />
                    <div className={styles.websiteTipContainer}>
                      <Tooltip show={showWebsiteTooltip}>
                        If you have your own website, you can link to it from your Puppies.com profile. Just enter the
                        website address here.
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.formTopRow}>
              <div className={styles.businessPhone}>
                <Field
                  label="Phone"
                  name="phone_formatted"
                  prefix="+1-"
                  prefixPadding={35}
                  component={TextInput}
                  className={touched.phone_formatted && errors.phone ? styles.inputError : null}
                  onChange={e => {
                    setFieldValue('phone_formatted', formatPhoneNumber(e.target.value));
                    setFieldValue('phone', formatPhoneNumber(e.target.value).replace(/-/g, ''));
                  }}
                />
                <InputError>{touched.phone_formatted && errors.phone}</InputError>
              </div>
            </div>
            <div className={styles.businessURL}>
              <StandaloneField
                label="Puppies.com Homepage Name"
                labelLinkRight="Copy homepage link"
                labelLinkRightClick={this.labelLinkRightClick}
                name="slug"
                component={TextInput}
                onChange={this.handleChangeSlug}
                value={values.slug}
                prefix="puppies.com/premier/"
                prefixPadding={158}
                onBlur={handleBlur}
                className={errors && touched.slug && errors.slug ? styles.inputError : null}
                placeholder="YourNameHere"
              />
              <div className={styles.businessURLtip}>
                <div
                  className={styles.businessURLhover}
                  onTouchStart={() => {
                    if (!showPremierSlugTooltip) {
                      this.setState({ showPremierSlugTooltip: true });
                    }
                  }}
                  onMouseEnter={() => this.setState({ showPremierSlugTooltip: true })}
                  onMouseLeave={() => this.setState({ showPremierSlugTooltip: false })}
                >
                  <span className={styles.businessURLtext}>What's this?</span>
                  <IconButton icon={'Info'} variant={'orange'} />
                  <div className={styles.businessURLTipContainer}>
                    <Tooltip show={showPremierSlugTooltip}>
                      This is a custom puppies.com homepage name, similar to a username. It will look like this:
                      puppies.com/premier/YourNameHere. You can use this to easily share your page.
                    </Tooltip>
                  </div>
                </div>
              </div>
              <InputError>{errors && touched.slug && errors.slug}</InputError>
            </div>
            <div className={styles.breedsAvailable}>
              <div className={styles.breedsAvailableInput}>
                <Field
                  name="breeds_available"
                  render={fieldProps => {
                    return (
                      <Autocomplete
                        key={values.breeds_available.join(',')}
                        label="Breeds Available"
                        disabled={values.formatted_breeds.length >= MAX_BREEDS}
                        placeholder={
                          values.formatted_breeds.length >= MAX_BREEDS ? 'Up to four breeds allowed' : 'Type a breed'
                        }
                        items={breeds.filter(breed => !values.breeds_available.includes(breed.id))}
                        onInputChange={this.handleInputChange}
                        onSelect={this.handleBreedSelect}
                        {...fieldProps}
                      />
                    );
                  }}
                />
              </div>
              <div className={styles.breedChips}>{this.renderBreedChips()}</div>
            </div>
            <div className={styles.facilityDescription}>
              <div className={styles.facilityDescriptionInput}>
                <Field
                  label="About your place"
                  name="facility_description"
                  component={Textarea}
                  className={styles.textarea}
                  required
                  value={values.facility_description}
                  placeholder="Writer's Block?&#10;Introduce yourself&#10;What makes your dogs special?&#10;What makes you a good breeder?&#10;How do you ensure your puppies are happy and healthy?"
                />
              </div>
              <div className={styles.facilityDescriptionInfo}>
                <span>
                  WARNING: Posting contact information here will make it publicly available.{' '}
                  <Link to="/blog/articles/lsptJkxc8l0pDk6X9KFRd" target="_blank">
                    Click here
                  </Link>{' '}
                  to learn about how to avoid scams and fraud.
                </span>
              </div>
            </div>
          </div>
          <LicensingRadioGroup user={user} values={values} />
        </div>
        <div className={styles.submitButtonWrapper}>
          <FormErrors errors={errors} />
          {status && status.error && <InputError>{status.error}</InputError>}
          {this.state.showPaymentModal ? this.renderPaymentModal() : null}
          <div className={styles.buttonWithIndicator}>
            <Button
              style={{ backgroundColor: status && status.success ? theme.colors.green : null }}
              type="button"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              buttonType="roundOrange"
              onClick={this.handleSubmitInteraction.bind(this, values, setSubmitting, setFieldError)}
            >
              {buttonCopy}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

const FormikPremierProfileForm = withFormik({
  mapPropsToValues: props => {
    const { profile, user } = props;
    let defaultPhone = '';
    let defaultPhoneFormatted = '';
    const defaultCountryCode = '+1';
    if (profile) {
      defaultPhone = profile.get('phone');
      defaultPhoneFormatted = formatPhoneNumber(profile.get('phone'));
    } else if (user.get('phone_number_verified')) {
      defaultPhone = user.get('phone_number');
      defaultPhoneFormatted = formatPhoneNumber(user.get('phone_number'));
    }
    return {
      id: profile ? profile.get('id') : undefined,
      name: profile ? profile.get('name') : '',
      website: profile ? profile.get('website') : '',
      phone: defaultPhone,
      phone_country_code: defaultCountryCode,
      phone_formatted: defaultPhoneFormatted,
      slug: profile ? profile.get('slug') : '',
      facility_description: profile?.get('facility_description') || user?.get('bio') || '',
      photos: profile
        ? profile
            .get('photos')
            .map(photo => ({ id: photo.get('id'), title: photo.get('title') }))
            .toJS()
        : [],
      breeds_available: profile
        ? profile
            .get('breeds')
            .map(breed => breed.get('id'))
            .toJS()
        : [],
      formatted_breeds: profile
        ? profile
            .get('breeds')
            .map(breed => breed.get('name'))
            .toJS()
        : [],
      is_federally_licensed: user.getIn(['userLicenses', 'is_federally_licensed']) || 'no',
      is_state_licensed: user.getIn(['userLicenses', 'is_state_licensed']) || 'no',
      federal_license_id: user.getIn(['userLicenses', 'federal_license_id']) || '',
      state_license_id: user.getIn(['userLicenses', 'state_license_id']) || '',
    };
  },

  enableReinitialize: true,

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(60, 'Name must be 60 characters or less')
      .required('Enter your kennel/breeder name'),
    breeds_available: yup.array().max(MAX_BREEDS),
    website: yup
      .string()
      .transform((_, originalValue) => {
        return prependHttps(originalValue);
      })
      .url('Must be a valid URL')
      .trim()
      .max('200', 'Must be 200 characters or less'),
    phone: phoneNumberSchema,
    slug: yup
      .string()
      .matches(/^[a-z0-9-_]+$/, {
        message: 'Letters and numbers only',
      })
      .trim()
      .min(2, 'URL must be at least 2 characters')
      .max(50, 'URL must be less than 50 characters'),
    facility_description: yup
      .string()
      .max(5000, 'Description must be less than 5000 characters')
      .required('Describe your facility'),
    federal_license_id: yup
      .string()
      .trim()
      .max(20, 'License ID must be 20 characters or less'),
    state_license_id: yup
      .string()
      .trim()
      .max(20, 'License ID must be 20 characters or less'),
  }),

  handleSubmit: async (values, { props, setFieldError, setSubmitting, setStatus, setErrors, setFieldValue }) => {
    const { formatted_breeds, ...restValues } = values;
    const competitors = [
      'gooddog.com',
      'greenfieldpuppies.com',
      'lancasterpuppies.com',
      'adoptapet.com',
      'puppyfinder.com',
      'welovedoodles.com',
      'keystonepuppies.com',
      'puppyspot.com',
      'buckeyepuppies.com',
      'premierpups.com',
      'petclassifieds.com',
      'nextdaypets.com',
      'hoobly.com',
      'uptownpuppies.com',
      'animalkingdomaz.com',
      'puppiessalenearme.com',
      'infinitypups.com',
      'bluediamondfamilypups.com',
      'pawrade.com',
      'vippuppies.com',
      'devotedtodog.com',
      'floridapuppiesforsale.net',
      'dallaspetland.com',
      'heavenlypuppy.com',
      'princesspuppies.com',
      'mygoldenretrieverpuppies.com',
      'gundogcentral.com',
      'dachworld.com',
      'pitbullforesthouse.com',
      'texassizebullies.com',
      'cutespupsforsale.com',
      'roxcpups.com',
      'selectdachshunds.com',
      'burrnationk9s.com',
      'brightstarlabradoodles.com',
      'sj-englishbulldogs.com',
      'trevinos-pitbulls.com',
      'brenglorabulldogs.com',
      'frostbornfarms.com',
      'dachshundsofcastleshield.com',
      'hunterridgekennels.com',
      'abbottkennels.com',
      'harmonystarkennel.com',
      'lesliesoklahomaenglishbulldogs.com',
      'flushingkimberkennels.com',
      'akc.com',
      'petfinder.com',
    ];
    let hasCompetitorSite = false;
    props.clearErrorBar();

    const submitValues = cloneDeep(restValues);

    delete submitValues.phone_formatted;

    if (submitValues.slug === '') {
      submitValues.slug = props.user.get('id');
    }

    if (submitValues.website) {
      const match = /([^.;+_]+)$/.exec(submitValues.website),
        result = match && match[1];
      if (!result) {
        setSubmitting(false);
        return setFieldError('website', 'This URL is invalid');
      }
      const tld =
        (result.includes('?') && result.substr(0, result.lastIndexOf('?'))) ||
        (result.includes('/') && result.substr(0, result.lastIndexOf('/'))) ||
        result;
      if (tld.length < 2) {
        setSubmitting(false);
        return setFieldError('website', 'This URL is invalid');
      }
      competitors.forEach(competitor => {
        if (submitValues.website.includes(competitor)) {
          hasCompetitorSite = true;
        }
      });
      if (hasCompetitorSite) {
        setSubmitting(false);
        return setFieldError('website', 'This URL is invalid');
      }

      const re = new RegExp('^(http|https)://', 'i');
      submitValues.website = re.test(submitValues.website) ? submitValues.website : 'https://' + submitValues.website;
      setFieldValue('website', submitValues.website);
    }

    submitValues.photos = submitValues.photos.map(
      ({ croppedImage, loading, percentage, type, value, ...rest }) => rest
    );

    setStatus({});
    const action = await props.submitAction(submitValues);
    setSubmitting(false);
    if (!action?.response.ok) {
      if (action?.json?.validationErrors) {
        const errors = action.json.validationErrors.reduce((c, e) => {
          return {
            ...c,
            [e.key]: e.message,
          };
        }, {});

        setErrors(errors);
      } else {
        props.setErrorBarMessage(action?.json ? action.json.message : 'An unknown error occurred');
      }
    } else {
      setStatus({ success: true });
      props.getUser();
      props.history.push(props.fromMembership ? `/account/membership` : `/premier/${submitValues.slug}`);
    }
  },
})(PremierProfileForm);

const styles = {
  title: props => ({
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.orange,
    marginBottom: '28px',
  }),
  formWrapper: {
    '> * + *': {
      marginTop: '34px',
      marginBottom: '40px',
    },
  },
  breedChips: props => ({
    '> *': {
      margin: '8px 8px 0 0',
    },
    [props.theme.breakpoints.mobile]: {
      marginLeft: '18px',
      marginTop: '10px',
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  }),
  formTopRow: props => ({
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '34px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      '> * + *': {
        marginTop: 0,
        marginLeft: '20px',
      },
    },
  }),
  businessName: props => ({
    [props.theme.breakpoints.mobile]: {
      width: '420px',
    },
  }),
  businessPhone: props => ({
    [props.theme.breakpoints.mobile]: {
      width: '160px',
    },
  }),
  businessWebsite: props => ({
    [props.theme.breakpoints.mobile]: {
      width: '250px',
    },
  }),
  businessURL: props => ({
    [props.theme.breakpoints.mobile]: {
      width: '100%',
      maxWidth: '600px',
    },
  }),
  businessURLtip: props => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '8px',
    color: props.theme.colors.orange,

    [props.theme.breakpoints.mobile]: {
      justifyContent: 'flex-start',
    },
  }),
  businessURLhover: props => ({
    display: 'flex',

    ':hover': {
      cursor: 'pointer',
    },
  }),
  businessURLtext: props => ({
    fontSize: '14px',
    marginRight: '5px',
  }),
  businessURLTooltip: props => ({
    position: 'relative',
    left: '25%',
    width: '400px !important',
  }),
  businessURLTipContainer: props => ({
    position: 'absolute',
    left: '50%',
    wordBreak: 'break-word',
    top: '5px',
    [props.theme.breakpoints.mobile]: {
      left: '25%',
    },
  }),
  websiteTip: props => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '8px',
    color: props.theme.colors.orange,

    [props.theme.breakpoints.mobile]: {
      justifyContent: 'flex-start',
    },
  }),
  websiteHover: props => ({
    display: 'flex',

    ':hover': {
      cursor: 'pointer',
    },
  }),
  websiteText: props => ({
    fontSize: '14px',
    marginRight: '5px',
  }),
  websiteTooltip: props => ({
    position: 'relative',
    left: '25%',
    width: '400px !important',
  }),
  websiteTipContainer: props => ({
    position: 'absolute',
    left: '50%',
    wordBreak: 'break-word',
    top: '5px',
    [props.theme.breakpoints.mobile]: {
      left: '25%',
    },
  }),
  breedsAvailable: props => ({
    [props.theme.breakpoints.mobile]: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  breedsAvailableInput: props => ({
    [props.theme.breakpoints.mobile]: {
      width: '250px',
      flexShrink: 0,
    },
  }),
  facilityDescription: props => ({
    [props.theme.breakpoints.tabletMid]: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  facilityDescriptionInput: props => ({
    [props.theme.breakpoints.mobile]: {
      width: '100%',
    },
  }),
  facilityDescriptionInfo: props => ({
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '5px 0 20px 0px',
    '> * + *': {
      marginTop: '20px',
    },
    '> ul': {
      '> * + *': {
        marginTop: '5px',
      },
    },
    '> span a': {
      color: 'rgb(26, 185, 196)',
    },
    width: '100%',
    [props.theme.breakpoints.tabletMid]: {
      width: '35%',
      marginLeft: '20px',
    },
  }),
  textarea: {
    height: '160px',
  },
  inputError: props => ({
    color: props.theme.colors.red,
    border: `1px solid ${props.theme.colors.red} !important`,
    boxShadow: `0px 0px 0px 1px ${props.theme.colors.red} !important`,
    ':focus': {
      outline: 'none', // Disable default focus glow
      borderColor: `${props.theme.colors.red} !important`,
    },
  }),
  submitButtonWrapper: props => ({
    paddingTop: '36px',
    marginTop: '10px',
    borderTop: props.theme.globalDashedBorder,
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '16px',
    },
  }),
  paymentModal: {
    borderRadius: '6px !important',
  },
  profileLink: {
    marginLeft: '10px',
  },
  buttonWithIndicator: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '16px',
    },
  },
  rule: {
    border: '0.5px dashed #979797',
    margin: '32px 0',
  },
};

export default compose(felaConnect(styles), withRouter)(FormikPremierProfileForm);
