import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import { CircleAvatar, HistoryLink, Td, TextLink, Tr } from '../../../../common/components';
import InteractiveBookmark from '../../../../common/components/icons/InteractiveBookmark';
import generateInitials from '../../../../common/utils/generateInitials';
import { useDoAfterLogin } from '../../../../contexts/DoAfterLogin';

import {
  BreederLocation,
  BreederNameContainer,
  BreederNameLink,
  BreedList,
  FavoriteButtonContainer,
  FavoriteContainer,
  FavoriteTableCell,
} from './styled';

export const MAX_BREED_COUNT = 3;

const SearchPremiersRow = ({
  addFavoritePremier = () => {},
  isMobile,
  premier = {},
  removeFavoritePremier = () => {},
}) => {
  const breedList = premier?.breeds?.slice(0, MAX_BREED_COUNT) ?? [];
  const doAfterLogin = useDoAfterLogin();
  const profileUrl = premier.slug !== 'null' ? `/premier/${premier.slug}` : `/profile/${premier.user_id}`;

  return (
    <Tr data-breederid={premier.premier_profile_id} data-testid="breeder">
      <Td>
        <BreederNameContainer>
          <HistoryLink backTo="Search" to={profileUrl}>
            <CircleAvatar
              initials={generateInitials(premier.name)}
              photo={premier.photos && premier.photos[0] && fromJS(premier.photos[0])}
              size={64}
              verified={premier?.user?.id_verified}
            />
          </HistoryLink>
          <div>
            <HistoryLink backTo="Search" to={profileUrl}>
              <BreederNameLink>{premier.name}</BreederNameLink>
            </HistoryLink>
            <BreederLocation>{premier?.user?.formatted_location}</BreederLocation>
          </div>
        </BreederNameContainer>
      </Td>
      {!isMobile && (
        <Td>
          <BreedList>
            {breedList.map(breed => (
              <HistoryLink key={breed.id} backTo="Search" to={`/find-a-puppy/${breed.slug}`} target="_blank">
                <TextLink>{breed.name}</TextLink>
              </HistoryLink>
            ))}
            {premier?.breeds?.length > MAX_BREED_COUNT && (
              <HistoryLink backTo="Search" to={profileUrl} target="_blank">
                <TextLink>+{premier.breeds.length - MAX_BREED_COUNT} more</TextLink>
              </HistoryLink>
            )}
          </BreedList>
        </Td>
      )}
      <FavoriteTableCell>
        {premier.has_premier_profile && (
          <FavoriteContainer>
            <FavoriteButtonContainer
              aria-label={'bookmark' + (premier.favorite ? ' bookmarked' : '')}
              data-testid="favorite-button-container"
              isFavorited={premier.favorite}
              onClick={() => {
                doAfterLogin(() => {
                  if (premier.favorite) {
                    removeFavoritePremier(premier.premier_profile_id);
                  } else {
                    addFavoritePremier(premier.premier_profile_id);
                  }
                });
              }}
            >
              <InteractiveBookmark fill={premier.favorite ? '#fa6125' : 'rgba(168, 168, 168, .5)'} />
            </FavoriteButtonContainer>
          </FavoriteContainer>
        )}
      </FavoriteTableCell>
    </Tr>
  );
};

SearchPremiersRow.propTypes = {
  addFavoritePremier: PropTypes.func,
  isMobile: PropTypes.bool,
  premier: PropTypes.shape({
    breeds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    favorite: PropTypes.bool,
    has_premier_profile: PropTypes.bool,
    name: PropTypes.string,
    photos: PropTypes.array,
    premier_profile_id: PropTypes.string,
    slug: PropTypes.string,
    user: PropTypes.shape({
      formatted_location: PropTypes.string,
      id_verified: PropTypes.bool,
    }),
  }),
  removeFavoritePremier: PropTypes.func,
};

export default SearchPremiersRow;
