import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListHeader from './ListHeader';

class PremierListHeader extends Component {
  static propTypes = {
    breedLabel: PropTypes.string,
    total: PropTypes.number,
    totalLabel: PropTypes.string,
    location: PropTypes.string,
  };

  static defaultProps = {
    totalLabel: 'found',
  };

  render() {
    const { breedLabel, total, totalLabel, formattedLocation, ...restProps } = this.props;

    return (
      <ListHeader {...restProps}>
        <strong>{total ? total : '0'}</strong> {breedLabel} {total === 1 ? 'breeder' : 'breeders'} {totalLabel}
        {formattedLocation ? ` near ${formattedLocation}` : ''}
      </ListHeader>
    );
  }
}

export default PremierListHeader;
