import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../../config';

import { getBreedFromUrl, getLocationFromUrl } from '../../utils/search';

export default function LandingPageMeta({ match, type }) {
  const breed = getBreedFromUrl(match?.params?.breedOrState);
  const matchLocation = getLocationFromUrl(match);

  let city = null;

  if ((breed && match?.params?.city) || (!breed && match?.params?.stateOrCity)) {
    city = matchLocation?.city?.toLowerCase?.();
  }

  const metaConfig = {
    city,
    slug: type === 'breed' ? match?.params?.slug : breed && match?.params?.breedOrState,
    state: matchLocation?.short,
    stateFull: matchLocation?.state?.toLowerCase?.(),
    type,
  };

  const title = getMetaTitle(metaConfig);
  const description = getMetaDescription(metaConfig);
  const canonical = getMetaCanonical(metaConfig).replace(/\s/g, '-');
  return (
    <Helmet>
      <title>{title}</title>
      {canonical && <link rel="canonical" href={canonical} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {canonical && <meta property="og:url" content={canonical} />}
      <meta property="og:image" content={`${config.frontendHost}/static/landingPageHero.jpg`} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
    </Helmet>
  );
}

function getMetaCanonical(metaConfig) {
  return `${config.frontendHost}${getPath(metaConfig)}`;
}

function getPath(metaConfig) {
  if (metaConfig.type === 'breed') {
    return `/breeds/${metaConfig.slug}`;
  }

  if (metaConfig.type === 'breeder') {
    let path = '/find-a-breeder';

    if (metaConfig.slug) {
      path = `${path}/${metaConfig.slug}`;
    }

    if (metaConfig.state) {
      path = `${path}/${metaConfig.stateFull || metaConfig.state}`;
    }

    if (metaConfig.city) {
      path = `${path}/${metaConfig.city}`;
    }

    return path;
  }

  let path = '/find-a-puppy';

  if (metaConfig.slug) {
    path = `${path}/${metaConfig.slug}`;
  }

  if (metaConfig.state) {
    path = `${path}/${metaConfig.stateFull || metaConfig.state}`;
  }

  if (metaConfig.city) {
    path = `${path}/${metaConfig.city}`;
  }

  return path;
}

function getMetaTitle(metaConfig = {}) {
  const breederOrPuppyText = getBreederOrPuppyText(metaConfig.slug, metaConfig.type);
  const locationText = getLocationText(metaConfig.city, metaConfig.state, metaConfig.type);
  return getTitle(breederOrPuppyText, locationText);
}

function getMetaDescription(metaConfig = {}) {
  const breederOrPuppyText = getBreederOrPuppyText(metaConfig.slug, metaConfig.type);
  const locationText = getLocationText(metaConfig.city, metaConfig.state, metaConfig.type);
  return getDescription(breederOrPuppyText, locationText);
}

function kebabToFormatted(str = '') {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function getBreederOrPuppyText(slug, type) {
  const noun = type === 'breeder' ? 'breeder' : 'puppy for sale';

  if (slug) {
    return `${kebabToFormatted(slug)} ${noun}`;
  }

  return noun;
}

function getLocationText(city, state) {
  if (city && state) {
    return ` in ${kebabToFormatted(city)}, ${state.toUpperCase()}`;
  }

  if (state && stateHash[state.toUpperCase()]) {
    return ` in ${stateHash[state.toUpperCase()]}`;
  }

  return '';
}

function getDescription(breederOrPuppyText, locationText) {
  return `Puppies.com will help you find your perfect ${breederOrPuppyText}${locationText}. We've connected loving homes to reputable breeders since 2003 and we want to help you find the puppy your whole family will love.`;
}

function getTitle(breederOrPuppyText, locationText) {
  if (!locationText) {
    return `Find your ${breederOrPuppyText} | Puppies.com`;
  }

  return `Find your ${breederOrPuppyText}${locationText} | Puppies.com`;
}

const stateHash = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
