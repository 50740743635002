import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import LandingPageMeta from '../../../../common/components/LandingPageMeta';

import SearchPremiersForm from './SearchPremiersForm';
import SearchPremiersResults from './SearchPremiersResults';

import { useManageSearchQuery, useSearchBreeders, useSearchValues } from '../../common/search-hooks';
import { buildInitialQuery, getInitialValues } from '../../common/search-utils';

import { searchPremiers } from '../../redux/actions';

const SearchPremiers = ({
  addFavoritePremier,
  hideSearchbar,
  history,
  isMobile,
  loaded,
  location,
  match,
  removeFavoritePremier,
  resetPremiersSearch = () => {},
  results,
  searchPremiers = () => {},
  total,
  user,
}) => {
  const { searchValues, setSearchValues } = useSearchValues({
    initialValues: getInitialValues({ location, match }),
    user,
  });

  useManageSearchQuery({
    history,
    match,
    path: location?.pathname,
    searchValues,
  });

  useSearchBreeders({
    loaded,
    path: location?.pathname,
    searchBreeders: searchPremiers,
    searchValues,
  });

  useEffect(() => {
    return () => {
      resetPremiersSearch();
    };
  }, [resetPremiersSearch]);

  return (
    <div>
      <LandingPageMeta match={match} type="breeder" />
      {!hideSearchbar && (
        <SearchPremiersForm query={searchValues} onSubmit={(values = {}) => setSearchValues({ ...values, page: 0 })} />
      )}
      <SearchPremiersResults
        addFavoritePremier={addFavoritePremier}
        breedLabel={searchValues.formatted_breed}
        isMobile={isMobile}
        loaded={loaded}
        removeFavoritePremier={removeFavoritePremier}
        results={results}
        total={total}
        handlePaginate={(values = {}) => setSearchValues(values)}
        formattedLocation={searchValues.formatted_location}
      />
    </div>
  );
};

SearchPremiers.propTypes = {
  addFavoritePremier: PropTypes.func,
  hideSearchbar: PropTypes.bool,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
  loaded: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.object,
  removeFavoritePremier: PropTypes.func,
  resetPremiersSearch: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  searchPremiers: PropTypes.func,
  tota: PropTypes.number,
  user: PropTypes.object,
};

SearchPremiers.fetchData = (location, match, user) => {
  return [searchPremiers(buildInitialQuery(match))];
};

export default SearchPremiers;
