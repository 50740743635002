import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import {
  Icon,
  Hero,
  LandingPageFooter,
  PopularBreedsFooter,
  Button,
  StartTrialModal,
  LandingPageCopy,
  Footer,
  ButtonLink,
} from '../../common/components';

const copy = [
  {
    title: "It's free to get started",
    description: (
      <Fragment>
        Getting started is <strong>free</strong> and only takes a few minutes. It's the first step in listing a puppy.
      </Fragment>
    ),
    image: '',
    imagePosition: 'left',
  },
  {
    title: 'Safely sell your puppies online',
    description: 'Since 2003, Puppies.com has been connecting breeders like you with people all over the world.',
    image: '',
    imagePosition: 'right',
  },
  {
    title: 'Always here to help',
    description: 'Have a question? Our US-Based customer support team is standing by to help.',
    image: '',
    imagePosition: 'left',
  },
];

class SellerLandingPage extends Component {
  state = {
    showTrialModal: false,
  };

  handleTrialStart = () => {
    this.props.history.push({ pathname: '/sign-up', state: { purchaseType: 'trial' } });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Sell Your Puppies Online at Puppies.com</title>
          <meta
            name="description"
            content="Selling your puppy online has never been easier than with the new Puppies.com. Advertise your puppy with a Puppies.com listing!"
          />
          <meta name="keywords" content="sell,puppy,online,advertise,list" />
        </Helmet>
        <Hero
          headline="Bring a little wiggle to someone's life!"
          buttonLeft={
            <ButtonLink to="/find-a-puppy" buttonType="roundWhite" iconLeft={<Icon size={14} icon="Search" />}>
              FIND A PUPPY
            </ButtonLink>
          }
          buttonRight={
            <ButtonLink to="/sign-in" buttonType="roundWhite" iconLeft={<Icon size={14} icon="Avatar" />}>
              SIGN IN
            </ButtonLink>
          }
          calloutAction={
            <Button buttonType="largeRoundOrangeOutline" onClick={() => this.setState({ showTrialModal: true })}>
              GET STARTED FOR FREE
            </Button>
          }
        />
        <LandingPageCopy sections={copy} headerText="Why choose Puppies.com?" />
        <LandingPageFooter />
        <PopularBreedsFooter />
        <Footer isLoggedIn={false} />
        <StartTrialModal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          onSubmit={this.handleTrialStart}
          closeOnEscape
          closeOnOutsideClick
        />
      </div>
    );
  }
}

export default SellerLandingPage;
