import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  ListHeader,
  Loading,
  NoContentPlaceholder,
  PuppyGrid,
  PuppyListHeader,
  StandaloneField,
} from '../../common/components';

import ResultsPager from './ResultsPager';

import getStateData from '../../common/utils/getStateData';

import { getSortOptions } from './common/search-config';

import theme from '../../theme';
import SearchBreedTab from './searchBreedTab';

const StyledSortField = styled(StandaloneField)`
  ${theme.breakpoints.mobile} {
    width: 160px !important;
  }
`;

const FilterButton = styled(Button)`
  width: 100%;
`;

const SearchResults = ({
  getBreedInformation,
  breedInformation,
  isFiltered,
  isMobile,
  handlePaginate,
  handleSaveSearch,
  handleSort,
  handleToggleFeatured,
  loaded,
  loading,
  location,
  query,
  randomTips,
  results,
  setMobilePaneOpen,
  total,
}) => {
  useEffect(() => {
    const footer = document.getElementById('fs-sticky-footer');
    if (footer) {
      footer.style.display = 'block';
    }
  }, []);

  if (!loaded || loading) {
    return <Loading center dark />;
  }

  const getNoResultsTitle = () => {
    const { breed = [], formatted_breed = '', formatted_location, search_by } = query;
    const breedSubstring = breed?.length === 1 ? `${formatted_breed} ` : '';
    let locationSubstring = formatted_location ? `in ${formatted_location} ` : '';

    if (search_by === 'state') {
      const stateData = getStateData(query.state);

      if (stateData) {
        locationSubstring = `in ${stateData.state} `;
      }
    }

    return `Sorry, no ${breedSubstring}puppies are available ${locationSubstring}at this time.`;
  };

  const getTotalLabel = () => {
    const { formatted_location, state } = query;

    if (!formatted_location && !state) {
      return 'For Sale';
    }

    if (!formatted_location) {
      const stateData = getStateData(state);
      return `For Sale In ${stateData.state}`;
    }

    return `For Sale Near ${formatted_location}`;
  };

  const actions = [
    {
      component: (
        <StandaloneField
          component={Checkbox}
          label="Featured Listings"
          labelIcon={<Icon icon="PremierBadge" size={16} />}
          name="premiers_only"
          value={query.premiers_only === 'true'}
          onChange={handleToggleFeatured}
        />
      ),
      enabled: !isMobile,
    },
    {
      component: (
        <Button buttonType="roundSmall" onClick={handleSaveSearch}>
          Save this Search
        </Button>
      ),
      enabled: isFiltered,
    },
    {
      component: (
        <FilterButton type="button" buttonType="roundSmall" onClick={setMobilePaneOpen}>
          Sort/Filter
        </FilterButton>
      ),
      enabled: isMobile,
    },
    {
      component: (
        <StyledSortField
          component={Dropdown}
          name="sort"
          options={getSortOptions(query)}
          value={query.sort || 'default'}
          variant="primary"
          onChange={handleSort}
        />
      ),
      enabled: !isMobile,
    },
  ];

  return (
    <div>
      {query.breed || query.formatted_location ? (
        <PuppyListHeader
          actions={actions.filter(action => action.enabled)}
          breedType={query?.breed?.length === 1 ? query?.formatted_breed[0] : null}
          horizontalActions
          total={!loading && results && total}
          totalLabel={getTotalLabel()}
        />
      ) : (
        <ListHeader>Thousands of puppies available worldwide!</ListHeader>
      )}
      <div>
        <PuppyGrid
          listings={results}
          location={location}
          noContent={
            <NoContentPlaceholder
              buttonText={isFiltered ? 'Save This Search' : null}
              description={isFiltered ? "Save this search and we'll notify you when they are." : null}
              globalPadding
              icon="PuppyOutline"
              title={getNoResultsTitle()}
              onClick={isFiltered ? handleSaveSearch : null}
            />
          }
          total={!loading && results && total}
          tips={randomTips}
        />
        <ResultsPager handlePaginate={handlePaginate} total={total} />

        {query.formatted_breed
          ? query.formatted_breed.map(breed => (
              <SearchBreedTab
                key={breed}
                breedName={breed}
                getBreedInformation={getBreedInformation}
                breedInformation={breedInformation}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default SearchResults;
