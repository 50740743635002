import React from 'react';
import PropTypes from 'prop-types';

import { Button, Pager } from '../../../../common/components';

import { ButtonContainer } from './styled';

const SearchPremiersPager = ({ handlePaginate, total = 0, viewAll }) => {
  if (total && viewAll) {
    return (
      <ButtonContainer>
        <Button buttonType="round" onClick={viewAll}>
          View All
        </Button>
      </ButtonContainer>
    );
  }

  return <Pager handlePaginate={handlePaginate} pageSize={16} totalItems={total} />;
};

SearchPremiersPager.propTypes = {
  handlePaginate: PropTypes.func,
  total: PropTypes.number,
  viewAll: PropTypes.func,
};

export default SearchPremiersPager;
