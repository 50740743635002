import styled from 'styled-components';

import { Td, TextLink, Th } from '../../../../common/components';

import theme from '../../../../theme';

export const BreedList = styled.div`
  height: 82px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  max-width: 400px;
  overflow: hidden;
  font-size: 12px;

  > * {
    display: inline-flex;
    padding: 3px 10px;
  }
`;

export const BreederLocation = styled.div`
  margin-top: 8px;
  font-size: 12px;
`;

export const BreederNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  & > * + * {
    margin-left: 12px;
  }

  ${theme.breakpoints.mobileLarge} {
    font-size: 16px;

    & > * + * {
      margin-left: 20px;
    }
  }
`;

export const BreederNameLink = styled(TextLink)`
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 80px;
  text-align: center;
`;

export const ColumnHeading = styled(Th)`
  left: inherit;
  padding-left: 94px !important;
`;

export const FavoriteButtonContainer = styled.div`
  cursor: pointer;
  position: relative;
  top: -2px;
  left: 2px;

  &:hover {
    > svg > path {
      fill: ${props => (props.isFavorited ? 'rgba(250, 97, 37, .5)' : 'rgba(255, 255, 255, .5)')};
    }
  }
`;

export const FavoriteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
  width: 100%;
`;

export const FavoriteTableCell = styled(Td)`
  overflow: hidden;
  padding: 0 !important;
  vertical-align: baseline !important;
`;

export const TableContainer = styled.div`
  margin-bottom: 50px;
`;
