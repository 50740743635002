import { useCallback, useEffect, useRef, useState } from 'react';
import querystring from 'query-string';

import { defaults, mapFormValuesToQuery, mapQueryToFormValues } from './search-config';
import { getQueryDifferences, getRedirectUrl, sanitizeQuery } from './search-utils';

import { getBreedFromUrl, getLocationFromUrl } from '../../../common/utils/search';

export const useManageSearchQuery = ({ history, match, path, searchValues }) => {
  const matchLocation = getLocationFromUrl(match, true);
  const city = matchLocation?.city?.toLowerCase();
  const formattedLocation = matchLocation?.formatted_location;
  const state = matchLocation?.short?.toLowerCase();
  const slug = match?.params?.breedOrState;

  useEffect(() => {
    const breed = getBreedFromUrl(slug);
    const comparison = mapQueryToFormValues(defaults);

    if (breed) {
      comparison.breed = breed.id;
      comparison.formatted_breed = breed.name;
    }

    if (city && state) {
      comparison.distance = 100;
      comparison.formatted_location = formattedLocation;

      if (searchValues.formatted_location) {
        comparison.formatted_location = searchValues.formatted_location;
      }
    }

    const query = mapFormValuesToQuery(searchValues);
    const diff = getQueryDifferences(query, mapFormValuesToQuery(comparison));
    const redirectUrl = getRedirectUrl(searchValues);
    const method = path === redirectUrl ? history?.replace : history?.push;

    method?.({
      pathname: redirectUrl,
      search: querystring.stringify(diff),
    });
  }, [city, formattedLocation, history, path, searchValues, slug, state]);
};

export const useSearchBreeders = ({ loaded, path, searchBreeders, searchValues }) => {
  const shouldBeRedirected = path !== getRedirectUrl(searchValues);
  const skipInitialSearch = useRef(loaded && typeof window === 'undefined');

  useEffect(() => {
    if (skipInitialSearch.current) {
      return (skipInitialSearch.current = false);
    }

    if (!shouldBeRedirected) {
      const query = mapFormValuesToQuery(searchValues);
      searchBreeders(sanitizeQuery(query));
    }
  }, [searchBreeders, searchValues, shouldBeRedirected]);
};

export const useSearchValues = ({ initialValues, user }) => {
  const [searchValues, _setSearchValues] = useState(mapQueryToFormValues(initialValues, user));

  const setSearchValues = useCallback(
    values => {
      _setSearchValues(currentValues => {
        return mapQueryToFormValues({ ...currentValues, ...values }, user);
      });
    },
    [user]
  );

  return { searchValues, setSearchValues };
};
