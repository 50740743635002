import querystring from 'query-string';

import { defaults, mapFormValuesToQuery } from './search-config';

import { getBreedFromUrl, getLocationFromUrl } from '../../../common/utils/search';

import getStateData from '../../../common/utils/getStateData';
import toKebabCase from '../../../common/utils/toKebabCase';

const DIFF_IGNORED_KEYS = ['city', 'state'];

export const buildInitialQuery = match => {
  const breed = getBreedFromUrl(match?.params?.breedOrState);
  const matchLocation = getLocationFromUrl(match, true);

  const settings = {
    ...defaults,
    breed: breed ? breed.id : '',
    city: matchLocation ? matchLocation.city?.toLowerCase() : '',
    distance: matchLocation ? 100 : 1000,
    formatted_breed: breed ? breed.name : '',
    formatted_location: matchLocation?.formatted_location,
    state: matchLocation ? matchLocation.short?.toLowerCase() : '',
  };

  return sanitizeQuery(mapFormValuesToQuery(settings));
};

export const getInitialValues = ({ location, match }) => {
  const queryValues = querystring.parse(location?.search);
  const breed = getBreedFromUrl(match?.params?.breedOrState);
  const matchLocation = getLocationFromUrl(match, true);

  const settings = {
    ...defaults,
    breed: breed ? breed.id : '',
    city: matchLocation ? matchLocation.city.toLowerCase() : '',
    distance: queryValues.distance || matchLocation ? 100 : 1000,
    formatted_breed: breed ? breed.name : '',
    formatted_location: matchLocation?.formatted_location ?? null,
    state: matchLocation ? matchLocation.short.toLowerCase() : '',
    ...queryValues, // override from query string
  };

  return settings;
};

export const getQueryDifferences = (query1 = {}, query2 = mapFormValuesToQuery(defaults)) => {
  return Object.keys(query1).reduce((diff, key) => {
    if (DIFF_IGNORED_KEYS.includes(key)) {
      return diff;
    }

    if (JSON.stringify(query1[key]) === JSON.stringify(query2[key])) {
      return diff;
    }

    return {
      ...diff,
      [key]: query1[key],
    };
  }, {});
};

export const getRedirectUrl = (settings = {}) => {
  const basePath = '/find-a-breeder';

  if (!Object.keys(settings).length) {
    return basePath;
  }

  let redirectPath = basePath;

  const hasBreed = settings.breed;
  if (hasBreed) {
    const breed = toKebabCase(settings.formatted_breed, ' ');
    redirectPath = `${redirectPath}/${breed}`;
  }

  const hasLocation = settings.formatted_location;

  if (hasLocation) {
    const location = settings.formatted_location.split(', ');
    const stateData = getStateData(location[1]);

    if (stateData) {
      const cityName = toKebabCase(location[0], ' ');
      const stateName = toKebabCase(stateData.state, ' ');

      redirectPath = `${redirectPath}/${stateName}/${cityName}`;
    }
  }

  return redirectPath;
};

export const sanitizeQuery = (searchQuery = {}) => {
  const sanitized = { ...searchQuery };

  delete sanitized.formatted_breed;
  delete sanitized.location;
  delete sanitized.sort;

  return sanitized;
};
