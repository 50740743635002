import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import moment from 'moment';
import queryString from 'query-string';
import classNames from 'classnames';

import theme from '../../../../theme';
import {
  Button,
  Icon,
  CircleAvatar,
  PuppyGrid,
  SubNav,
  Modal,
  LightBox,
  Loading,
  PuppyListHeader,
  ConfirmationModal,
  PlainLink,
  ButtonPlain,
  TestimonialList,
  ContentNotFound,
  ContactButton,
  VerifyEmailModal,
  ContentCapitalized,
  LicenseBadge,
} from '../../../../common/components';
import PencilOutlineIcon from '../../../../common/components/icons/PencilOutline';
import InteractiveBookmark from '../../../../common/components/icons/InteractiveBookmark';
import generateInitials from '../../../../common/utils/generateInitials';
import ContactPremierModal from '../../../../common/components/ContactPremierModal';
import removeFromQueryString from '../../../../common/utils/remove-from-query-string';
import { getFormattedPhoneNumber } from '../../../../common/utils/format-phone';
import hasTouch from '../../../../common/utils/hasTouch';
import PremierUpdateModal from '../../../../common/components/PremierUpdateModal';
import { getPremierBySlug, resetPremier } from '../../redux/actions';
import { getProfileListingsBySlug } from '../../../Profile/redux/actions';
import { DoAfterLoginContext } from '../../../../contexts/DoAfterLogin';
import { getLegacyUser } from '../../../../redux/actions';
import { Redirect } from 'react-router-dom';
import ViewPremierMeta from './ViewPremierMeta';

function isUuid(input) {
  const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  return uuidRegex.test(input);
}

function isInteger(input) {
  return new RegExp(/^[0-9]*$/).test(input);
}

class ViewPremier extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(props.location.search);

    this.state = {
      isCollapsed: true,
      showLightBox: false,
      availablePhotos: null,
      showContactModal: !!search.contact && props.user && props.user.get('email_verified'),
      showVerifyEmailModal: !!search.contact && props.user && !props.user.get('email_verified'),
      showPremierUpdateModal: false,
      showConfirmDeleteUpdate: false,
      requestVerification: !!parseInt(search.verification, 10) && !!props.user,
    };
  }

  static contextType = DoAfterLoginContext;

  static fetchData(location, match, user, premier = null) {
    const slug = match.params.slug;
    const dataFunctions = [getPremierBySlug(slug), getProfileListingsBySlug(slug)];
    if (isInteger(slug) || isUuid(slug)) {
      dataFunctions.push(getLegacyUser(slug));
    }
    return dataFunctions;
  }

  toggleCollapsed = e => {
    this.setState(state => ({ isCollapsed: !state.isCollapsed }));
  };

  componentDidMount() {
    const {
      location,
      match,
      user,
      dispatch,
      loaded,
      listings,
      premier,
      getProfileListings,
      listingsError,
    } = this.props;

    if (!loaded) {
      ViewPremier.fetchData(location, match, user, premier).forEach(action => dispatch(action));
    }
    if (premier && premier.getIn(['user', 'id']) && !listings && !listingsError) {
      getProfileListings(premier.getIn(['user', 'id']));
    }
  }

  componentDidUpdate(prevProps) {
    const { premier, getProfileListings, history, user } = this.props;

    if (prevProps.premier !== premier && premier.getIn(['user', 'id'])) {
      getProfileListings(premier.getIn(['user', 'id']));
    }
    if (this.state.showContactModal && premier.get('conversation_id')) {
      history.push(`/messages/inbox/${premier.get('conversation_id')}`);
    }

    const search = queryString.parse(this.props.location.search);
    const prevSearch = queryString.parse(prevProps.location.search);

    if (prevSearch.verification !== search.verification) {
      this.setState({ requestVerification: !!parseInt(search.verification, 10) && !!user });
    }

    if (prevSearch.contact !== search.contact) {
      this.setState({
        showContactModal: !!search.contact && user && user.get('email_verified'),
        showVerifyEmailModal: !!search.contact && user && !user.get('email_verified'),
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetPremier());
    this.props.resetSendMessageToUser();
  }

  navigateToEditPremierProfile = () => {
    const { history, location } = this.props;
    history.push({
      pathname: `/account/premier-profile`,
      state: {
        backTo: 'Profile',
        prevLocation: location,
      },
    });
  };

  shouldShowContactModal() {
    const { premier, user, sendMessageLoaded } = this.props;
    return (
      this.state.showContactModal &&
      premier &&
      user &&
      (!premier.get('conversation') || sendMessageLoaded) &&
      premier.get('user_id') !== user.get('id')
    );
  }

  showAuthModal() {
    const { showAuthenticationModal } = this.props;
    showAuthenticationModal();
  }

  getLatestUpdate() {
    const { premier } = this.props;

    if (premier.getIn(['latestUpdate', 'deleted_at']) === null) {
      return premier.get('latestUpdate');
    }
    return null;
  }

  renderContactButton = (options = {}) => {
    const { premier, location, user } = this.props;
    const isUser = !!(user && premier.getIn(['user', 'id']) === user.get('id'));
    const userIsBlocked = premier?.get('blocked') || false;

    if (userIsBlocked) {
      return (
        <Button buttonType={'round'} disabled={true}>
          Contact Me
        </Button>
      );
    } else {
      return (
        <ContactButton
          conversation={premier.get('conversation')}
          user={user}
          isSelf={isUser}
          location={location}
          backTo="Profile"
          onStartContact={requestVerification => this.setState({ showContactModal: true, requestVerification })}
          showVerifyEmailModal={this.state.showVerifyEmailModal}
          onOpenVerify={() => this.setState({ showVerifyEmailModal: true })}
          isRequestVerificationButton={!!options.requestVerificationButton}
          contactText="Contact"
        />
      );
    }
  };

  renderSubNav = () => {
    const { premier, user } = this.props;
    const isUser = user && premier.getIn(['user', 'id']) === user.get('id');
    return (
      <div>
        <SubNav
          titleText={isUser ? 'This is Your Premier Profile' : null}
          headerRightChildren={
            isUser ? (
              <Button
                buttonType="white"
                onClick={this.navigateToEditPremierProfile}
                iconLeft={<Icon icon="Pencil" size={16} />}
              >
                Edit profile
              </Button>
            ) : null
          }
        />
      </div>
    );
  };

  renderWebsiteAndPhone = () => {
    const { premier, setActiveModal, styles, user } = this.props;

    if (premier.get('hideContactInformation')) {
      return null;
    }

    if (premier.get('website') || premier.get('phone')) {
      return (
        <div className={styles.websitePhone}>
          {premier.get('website') ? (
            <a
              className={styles.websiteLink}
              href={premier.get('website')}
              onClick={e => {
                e.preventDefault();

                this.props.trackPremierWebsiteView(premier.get('id'));
                if (typeof window !== 'undefined') {
                  window.open(premier.get('website'), '_blank');
                }
              }}
              rel="noopener noreferrer"
            >
              <span>Visit Website</span>
              <Icon icon="OpenLink" fill={theme.colors.navyBlue} size={20} />
            </a>
          ) : null}
          {'  '}
          {user ? (
            <span>{getFormattedPhoneNumber(premier.get('phone'))}</span>
          ) : (
            <ButtonPlain onClick={() => setActiveModal('signup')}>View phone number</ButtonPlain>
          )}
        </div>
      );
    }
    return null;
  };

  renderBreedsAvailable = () => {
    const { premier, styles } = this.props;
    if (premier.get('breeds')) {
      return (
        <div className={styles.breedsAvailable}>
          {!!premier.get('breeds').size && <span>Breeds Available:</span>}
          {premier.get('breeds').map((breed, i) => (
            <PlainLink noUnderline to={`/breeds/${breed.get('slug')}`} key={breed.get('id')}>
              {breed.get('name')}
            </PlainLink>
          ))}
        </div>
      );
    }
    return null;
  };

  renderInfo = () => {
    const { premier, styles, accountAge } = this.props;
    const photosToDisplay = premier.get('photos');
    return (
      <div className={styles.infoWrapper}>
        <Modal
          transparent
          fullLightbox
          closeOnEscape={true}
          closeOnOutsideClick={true}
          isOpen={this.state.showLightBox}
          onClose={() => {
            this.setState({ showLightBox: false });
          }}
        >
          <LightBox
            photos={photosToDisplay}
            onClose={() => {
              this.setState({ showLightBox: false });
            }}
          />
        </Modal>
        <div className={styles.accountLeft}>
          <CircleAvatar
            size={150}
            onClick={() => {
              if (premier.get('photos').size > 0) {
                this.setState({ showLightBox: true });
              }
            }}
            hasLightbox
            photo={premier.getIn(['photos', 0])}
            initials={generateInitials(premier.get('name'))}
            carouselImages={photosToDisplay.size > 1 ? photosToDisplay : null}
            hasMultipleImages={premier.get('photos').size > 1}
            alt={'profile'}
            verified={premier.getIn(['user', 'id_verified'])}
          />
          <div className={styles.accountAge}>
            A member for <strong>{accountAge}</strong>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.premierNameAndTag}>
            <h1 aria-label="Premier Name" className={styles.title}>
              {premier.get('name')}
            </h1>
            {this.renderFavoriteTag()}
          </div>
          <div className={styles.premierMeta}>
            <div className={styles.location}>
              <span>{premier.getIn(['user', 'formatted_location']).replace(', USA', '')}</span>
              {this.renderWebsiteAndPhone()}
            </div>
            {this.renderBreedsAvailable()}
            <div className={styles.breedsAvailable}>
              <span>Licenses:</span>
              <LicenseBadge type="federal" user={premier.get('user')} />
              <LicenseBadge type="state" user={premier.get('user')} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDescription = () => {
    const { premier, styles } = this.props;
    return (
      <div className={styles.description}>
        <h1>About our place</h1>
        <p>{premier.get('facility_description')}</p>
      </div>
    );
  };

  renderVerificationData = () => {
    const { premier, styles } = this.props;
    return (
      <div className={styles.verificationData}>
        <div className={styles.verificationDataHeader}>
          <div className={styles.verificationDataInfo}>
            <CircleAvatar
              initials={generateInitials(
                premier.getIn(['user', 'last_initial']),
                premier.getIn(['user', 'first_name'])
              )}
              photo={premier.getIn(['user', 'photo'])}
              variant="white"
              size={45}
              verified={premier.getIn(['user', 'id_verified'])}
            />
            <div className={styles.verificationDataName}>
              <span>Primary Contact</span>
              <br />
              <span>
                <strong>
                  <ContentCapitalized>
                    {premier.getIn(['user', 'first_name']).replace(/^\w/, c => c.toUpperCase())}{' '}
                    {premier.getIn(['user', 'last_initial'])}
                  </ContentCapitalized>
                </strong>
              </span>
            </div>
          </div>
          <div className={styles.contactPremierBtn}>{this.renderContactButton()}</div>
        </div>
        <div className={styles.verificationTagsWrapper}>
          <div className={styles.verificationTags}>
            <div className={styles.verificationTag}>
              <Icon icon="PremierBadge" fill={theme.colors.green} size={28} />
              <span>Featured Breeder</span>
            </div>

            <div className={classNames([styles.verificationTag, styles.idVerified])}>
              {premier.getIn(['user', 'id_verified']) ? (
                <Icon icon="StarCircle" size={28} />
              ) : (
                <Icon icon="CircleX" size={28} />
              )}
              <div>
                ID {premier.getIn(['user', 'id_verified']) ? null : <span style={{ fontWeight: 'bold' }}>Not</span>}{' '}
                Verified
              </div>
            </div>

            {premier.getIn(['user', 'email_verified']) ? (
              <div className={styles.verificationTag}>
                <Icon icon="CheckCircle" fill={theme.colors.green} size={28} />
                <span>Email Verified</span>
              </div>
            ) : null}
            <div className={styles.verificationTag}>
              {premier.getIn(['user', 'phone_number_verified']) ? (
                <Icon icon="CheckCircle" size={28} />
              ) : (
                <Icon icon="CircleX" size={28} />
              )}
              <div>
                Phone{' '}
                {premier.getIn(['user', 'phone_number_verified']) ? null : (
                  <span style={{ fontWeight: 'bold' }}>Not</span>
                )}{' '}
                Verified
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderToggleCollapsed = () => {
    const { isCollapsed } = this.state;
    const { total } = this.props;
    if (total > 8) {
      return (
        <Button
          onClick={this.toggleCollapsed}
          buttonType="round"
          minwidth="unset"
          iconRight={isCollapsed ? <Icon icon="CaretDown" size={18} /> : <Icon icon="CaretUp" size={18} />}
        >
          <span>{isCollapsed ? 'Show All' : 'Show Less'}</span>
        </Button>
      );
    }
    return null;
  };

  renderListings = () => {
    const { listings, location, total, styles } = this.props;
    const listingsToDisplay = listings;
    return (
      <div className={styles.puppyGrid}>
        <PuppyGrid
          total={total}
          header={<PuppyListHeader total={total} actions={[this.renderToggleCollapsed()]} totalLabel="available" />}
          listings={listingsToDisplay}
          location={location}
          isPremier={true}
          noAds
        />
      </div>
    );
  };

  renderFavoriteTag = () => {
    const { premier, addFavoritePremier, removeFavoritePremier, styles } = this.props;
    return (
      <div
        className={styles.favoriteIcon}
        aria-label={'bookmark' + (premier.get('favorite') ? ' bookmarked' : '')}
        onClick={() => {
          this.context.doAfterLogin(() => {
            if (premier.get('favorite')) {
              removeFavoritePremier(premier.get('id'));
            } else {
              addFavoritePremier(premier.get('id'));
            }
          });
        }}
      >
        <InteractiveBookmark stroke={theme.colors.orange} fill={premier.get('favorite') ? theme.colors.orange : null} />
      </div>
    );
  };

  renderPremierUpdate() {
    const { premier, user, styles } = this.props;
    const isOwnProfile = user && premier.get('user_id') === user.get('id');
    const latestUpdate = this.getLatestUpdate();
    if (!latestUpdate) {
      return (
        <div className={styles.premierNewsWrapper} data-testid="puppy-updates">
          <div className={styles.premierNews}>
            <div className={styles.premierNewsContent}>
              <div className={styles.premierNewsLeft}>
                <Icon icon="Megaphone" size={54} fill={theme.colors.yellow} />
                <h1>Puppy Updates</h1>
              </div>
              <div className={styles.premierNewsRight}>
                <div className={styles.noContentBox}>
                  <PencilOutlineIcon />
                  <h3>Nothing yet!</h3>
                  <p>
                    {isOwnProfile
                      ? 'This will be visible to the public on your Premier Profile. Keep visitors to your page in the loop with a short update. Talk about new litters or any other changes!'
                      : `This breeder hasn't written any updates yet, maybe ask them why?`}
                  </p>
                  {isOwnProfile && (
                    <Button
                      buttonType="round"
                      onClick={isOwnProfile ? () => this.setState({ showPremierUpdateModal: true }) : null}
                    >
                      Post an Update
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.premierNewsWrapper} data-testid="puppy-updates">
        <div className={styles.premierNews}>
          <div className={styles.premierNewsContent}>
            <div className={styles.premierNewsLeft}>
              <Icon icon="Megaphone" size={54} fill={theme.colors.yellow} />
              <h1>Puppy Updates</h1>
            </div>
            <div className={styles.premierNewsRight}>
              <div>
                <strong>{moment(latestUpdate.get('created_at')).format('MM/DD/YYYY')}</strong>
              </div>
              <p className={styles.latestUpdate}>{latestUpdate.get('body')}</p>
            </div>
          </div>
          {isOwnProfile && (
            <div className={styles.premierNewsButtonRow}>
              <ButtonPlain variant="red" onClick={() => this.setState({ showConfirmDeleteUpdate: true })}>
                Delete
              </ButtonPlain>
              <Button
                buttonType="round"
                iconLeft={<Icon icon="Pencil" size={16} />}
                onClick={() => this.setState({ showPremierUpdateModal: true })}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  render404() {
    return (
      <ContentNotFound
        icon="PuppyOutline"
        to="/find-a-breeder"
        buttonText="Find A Featured Breeder"
        title="Sorry, we couldn't find the page you were looking for."
      />
    );
  }

  render() {
    const {
      premier,
      deletePremierUpdate,
      loading,
      listings,
      loaded,
      location,
      styles,
      history,
      user,
      legacyUser,
      match,
    } = this.props;
    const { requestVerification, showVerifyEmailModal } = this.state;

    if (loading || !loaded) {
      return <Loading dark center />;
    }

    if (!premier.get('id')) {
      if (legacyUser.get('exists')) {
        return <Redirect to={`/profile/${match.params.slug}`} />;
      }

      return this.render404();
    }

    const query = queryString.parse(location.search);
    const viewerId = user && user.get('id') ? user.get('id') : null;
    const isSelf = !!(user && premier.getIn(['user', 'id']) === user.get('id'));
    return (
      <div>
        <ViewPremierMeta premier={premier} listings={listings} />
        {this.renderSubNav()}
        <div className={styles.wrapper}>
          {loaded && this.renderInfo()}
          <div className={styles.pageBody}>
            {loaded && this.renderDescription()}
            {loaded && this.renderVerificationData()}
          </div>
        </div>
        {this.renderPremierUpdate()}
        <ConfirmationModal
          isOpen={this.state.showConfirmDeleteUpdate}
          onClose={() => this.setState({ showConfirmDeleteUpdate: false })}
          onConfirm={deletePremierUpdate.bind(this, premier.getIn(['latestUpdate', 'id']))}
          title="Are you sure?"
          subTitle="Delete this puppy update?"
          closeOnOutsideClick
        />
        {listings && this.renderListings()}
        <TestimonialList
          include="for"
          userId={premier.get('user_id')}
          viewerId={viewerId}
          page={parseInt(query.page, 10) || 0}
          pageSize={parseInt(query.page_size, 10) || 16}
          className={styles.testimonials}
          premier={premier}
          showAuthModal={() => this.showAuthModal()}
          isEmailVerified={user ? user.get('email_verified') : null}
          showVerifyEmailModal={showVerifyEmailModal}
          canLeaveTestimonial={premier.get('canLeaveTestimonial')}
          showHidden={false}
          handleEmailIsNotVerified={() =>
            this.setState({
              showVerifyEmailModal: true,
            })
          }
        />
        <ContactPremierModal
          isOpen={this.shouldShowContactModal()}
          premier={premier}
          requestVerification={requestVerification}
          onClose={() =>
            this.setState({ showContactModal: false, requestVerification: false }, () => {
              history.replace(location.pathname + removeFromQueryString(location.search, ['contact', 'verification']), {
                ...location.state,
              });
            })
          }
        />
        <PremierUpdateModal
          isOpen={this.state.showPremierUpdateModal}
          onClose={() => this.setState({ showPremierUpdateModal: false })}
          currentUpdate={this.getLatestUpdate()}
        />
        <VerifyEmailModal
          isOpen={!isSelf && showVerifyEmailModal}
          onClose={() => this.setState({ showVerifyEmailModal: false })}
          user={user}
        />
      </div>
    );
  }
}

const styles = props => ({
  wrapper: props => ({
    [props.theme.breakpoints.mobileLarge]: {
      ...props.theme.globalPageWrapper,
    },
  }),
  infoWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: props.theme.colors.tan,
    padding: '24px',
    '> * + *': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '16px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      borderRadius: '4px',
      flexDirection: 'row',
      '> * + *': {
        alignItems: 'flex-start',
        marginTop: 0,
        marginLeft: '50px',
      },
    },
  },
  info: {
    width: '100%',
    minWidth: 0,
  },
  premierNameAndTag: {
    minWidth: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      justifyContent: 'flex-start',
    },
    '> h1': {
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    fontSize: '25px',
    lineHeight: '31px',
    color: props.theme.colors.orange,
    fontWeight: props.theme.typography.sansBold,
    textAlign: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      textAlign: 'left',
      fontSize: '34px',
      lineHeight: '41px',
    },
  },
  favoriteIcon: {
    position: 'absolute',
    top: '31px',
    right: '21px',
    width: '23px',
    height: '23px',
    marginLeft: '10px',
    display: 'inline-block',
    cursor: 'pointer',
    ':hover': {
      '> svg > path': {
        fill:
          !hasTouch() && !props.premier.get('favorite')
            ? 'rgba(250, 97, 37, .20)'
            : !hasTouch() && !!props.premier.get('favorite')
            ? 'rgba(250, 97, 37, .6)'
            : null,
      },
    },
    '>svg': {
      height: '100%',
      width: '100%',
    },
    [props.theme.breakpoints.mobileLarge]: {
      position: 'static',
    },
  },
  accountLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accountAge: {
    display: 'inline-block',
    padding: '6px 18px',
    marginTop: '16px',
    backgroundColor: '#7ED321',
    fontSize: '14px',
    color: props.theme.colors.white,
    borderRadius: '100px',
    width: 'max-content',
  },
  premierMeta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  location: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '20px',
    color: props.theme.colors.navyBlue,
    '> * + *': {
      marginTop: '10px',
    },
    '@media (max-width: 810px)': {
      alignItems: 'center',
    },
  },
  websitePhone: {
    color: props.theme.colors.navyBlue,
    fontWeight: props.theme.typography.sansBold,
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '10px',
    },
  },
  websiteLink: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    opacity: '1.0',
    '> * + *': {
      marginLeft: '4px',
    },
    '@media (max-width: 810px)': {
      justifyContent: 'center',
    },
    ':hover': {
      opacity: '0.66',
    },
  },
  premierWebsite: {
    maxWidth: '66%',

    [props.theme.breakpoints.mobileSmall]: {
      marginBottom: '30px',
    },
  },
  premierWebsiteUrl: {
    width: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
  premierPhone: {
    flex: 1,
    textAlign: 'right',

    [props.theme.breakpoints.mobileSmall]: {
      marginBottom: '20px',
    },
  },
  dot: {
    display: 'none',
    [props.theme.breakpoints.mobileLarge]: {
      display: 'inline-block',
      margin: '0 6px',
    },
  },
  breedsAvailable: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
    marginLeft: 0,
    '> * + *': {
      marginTop: '10px',
    },
    color: props.theme.colors.navyBlue,
    '> a': {
      marginTop: '10px',
      textAlign: 'center',
    },
    [props.theme.breakpoints.mobileLarge]: {
      alignItems: 'flex-start',
      marginLeft: '50px',
      marginTop: '20px',
      '> a': {
        marginTop: '6px',
      },
    },
  },

  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    [props.theme.breakpoints.mobileLarge]: {
      marginTop: '46px',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  description: {
    order: 2,
    marginTop: '40px',
    ...props.theme.globalPadding,
    [props.theme.breakpoints.mobileLarge]: {
      padding: 0,
      marginTop: 0,
      maxWidth: '510px',
      order: 1,
    },
    '>h1': {
      ...props.theme.typography.titleText,
    },
    '>p': {
      margin: '20px 0',
      fontSize: '14px',
      lineHeight: '20px',
      color: props.theme.colors.brown,
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
    },
  },
  verificationData: {
    order: 1,
    display: 'flex',
    flexDirection: 'column',
    [props.theme.breakpoints.mobileLarge]: {
      order: 2,
      marginTop: 0,
      width: '396px',
    },
  },
  verificationDataHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: props.theme.colors.yellow,
    padding: '32px 24px',
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
      borderRadius: '4px',
    },
  },
  verificationDataInfo: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '16px',
    },
  },
  verificationDataName: {
    lineHeight: '16px',
    fontSize: '12px',
    fontWeight: props.theme.typography.sansLight,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [props.theme.breakpoints.mobileLarge]: {
      maxWidth: '115px',
    },
  },
  puppyGrid: {
    margin: '40px 0',
  },
  verificationTagsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '30px',
    borderBottom: '1px solid #D8D8D8',
    '> * + *': {
      marginTop: '38px',
    },
    [props.theme.breakpoints.mobileLarge]: {
      alignItems: 'flex-start',
      padding: '38px 24px 0',
      borderBottom: 'none',
      '> button': {
        marginLeft: '52px',
      },
    },
  },
  verificationTags: {
    '> * + *': {
      marginTop: '38px',
    },
  },
  verificationTag: {
    display: 'flex',
    alignItems: 'center',
    color: '#4B4E52',
    '> * + *': {
      marginLeft: '8px',
    },
    marginRight: '30px',
    marginBottom: '24px',
    [props.theme.breakpoints.mobileLarge]: {
      marginRight: 0,
      marginBottom: 0,
    },
  },
  idVerified: {
    '>span': {
      color: props.theme.colors.brighterBlue,
    },
    '>div': {
      color: props.theme.colors.textDark,
    },
  },
  premierNewsWrapper: {
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '40px auto',
  },
  premierNews: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: props.theme.colors.tan,
    borderRadius: '4px',
  },
  premierNewsContent: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    [props.theme.breakpoints.mobileLarge]: {
      flexDirection: 'row',
      alignItems: 'center',
      '> * + *': {
        marginLeft: '40px',
        marginTop: 0,
      },
    },
  },
  premierNewsLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> h1': {
      ...props.theme.typography.titleText,
      marginTop: '10px',
      textAlign: 'center',
      [props.theme.breakpoints.mobileLarge]: {
        width: '118px',
      },
    },
  },
  premierNewsButtonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '20px',
    borderTop: props.theme.globalDashedBorder,
    fontSize: '14px',
    '> * + *': {
      marginLeft: '20px',
    },
    '> button': {
      padding: '2px 4px',
      fontSize: 'inherit',
      minWidth: '70px',
      '> div > span': {
        marginRight: '4px',
      },
    },
  },
  premierNewsRight: {
    color: props.theme.colors.brown,
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '20px',
    marginTop: '20px',
    alignSelf: 'flex-start',
    width: '100%',
    [props.theme.breakpoints.mobileLarge]: {
      alignSelf: 'center',
      marginTop: 0,
    },
  },
  latestUpdate: {
    marginTop: '6px',
    whiteSpace: 'pre-line',
  },
  noContentBox: {
    backgroundColor: props.theme.colors.tan,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    '> h3': {
      ...props.theme.typography.titleText,
      marginTop: '25px',
    },
    '> p': {
      marginTop: '16px',
    },
    '> button': {
      marginTop: '24px',
    },
  },
  testimonials: {
    ...props.theme.globalPadding,
  },
  subnavChildren: {
    paddingBottom: '10px',
    textAlign: 'center',
    [props.theme.breakpoints.mobileLarge]: {
      paddingBottom: 0,
      textAlign: 'left',
    },
  },
  contactPremierBtn: {
    marginTop: '16px',
    [props.theme.breakpoints.mobileLarge]: {
      marginTop: 0,
      marginLeft: '10px',
    },
    flexShrink: 0,
  },
});

export default felaConnect(styles)(ViewPremier);
