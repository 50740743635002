import React, { useEffect, useState, useRef } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';

import MobileSortFilterPane from './MobileSortFilterPane';
import SearchHeader from './SearchHeader';
import SearchResults from './SearchResults';
import ToggleAdvancedSearch from './ToggleAdvancedSearch';
import SaveSearchForm from './components/SaveSearchForm';

import { Modal } from '../../common/components';
import LandingPageMeta from '../../common/components/LandingPageMeta';
import { useDoAfterLogin } from '../../contexts/DoAfterLogin';

import { searchListings, sendBrowserFingerprint } from './redux/actions';
import { getRandomTips } from '../../redux/actions';

import { getSortOptions, SORT_OPTIONS } from './common/search-config';
import { useManageSearchQuery, useSearchListings, useSearchValues } from './common/search-hooks';
import { buildInitialQuery, getInitialValues } from './common/search-utils';
import PhoneVerificationModal from '../VerifyPhone/PhoneVerificationModal';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const SearchListings = ({
  getBreedInformation,
  breedInformation,
  addSavedSearch,
  getRandomTips = () => {},
  history,
  isMobile,
  location,
  loading,
  loaded,
  match,
  results,
  randomTips,
  resetListingsSearch = () => {},
  searchListings,
  total,
  user,
}) => {
  const doAfterLogin = useDoAfterLogin();
  const prevExpiredLoaded = usePrevious(user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobilePaneOpen, setIsMobilePaneOpen] = useState(false);

  const { isAdvancedSearch, searchValues, setSearchValues, toggleAdvancedSearch } = useSearchValues({
    initialValues: getInitialValues({ isMobile, location, match }),
    user,
  });

  const isFiltered = useManageSearchQuery({
    history,
    isAdvancedSearch,
    match,
    path: location?.pathname,
    searchValues,
  });

  useSearchListings({
    loaded,
    path: location?.pathname,
    searchListings,
    searchValues,
  });

  useEffect(() => {
    if (!randomTips || randomTips.size === 0) {
      getRandomTips();
    }

    return () => {
      resetListingsSearch();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function submitFingerprint() {
      const fpPromise = FingerprintJS.load();
      const fp = await fpPromise;
      const result = await fp.get();
      await sendBrowserFingerprint(result.visitorId);
      const cookieOptions = {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        domain: '.' + window.location.host,
        secure: true,
        sameSite: 'strict',
      };
      Cookies.set('browser_check', 'ok', cookieOptions);
    }
    const fpSet = Cookies.get('browser_check');
    if (!prevExpiredLoaded && user) {
      if (!fpSet || fpSet !== 'ok') {
        submitFingerprint();
      }
    }
  }, [prevExpiredLoaded, user]);

  return (
    <div>
      <LandingPageMeta match={match} type="listing" />
      <SearchHeader
        isAdvancedSearch={isAdvancedSearch}
        isMatchmakerSearch={searchValues.show_matchmaker}
        isMobile={isMobile}
        query={searchValues}
        onSubmit={values => {
          setSearchValues({ ...values, page: 0 });
        }}
      />
      <ToggleAdvancedSearch
        isAdvancedSearch={isAdvancedSearch}
        isMatchmakerSearch={searchValues.show_matchmaker}
        isMobile={isMobile}
        handleToggleAdvanced={toggleAdvancedSearch}
      />
      <SearchResults
        breedInformation={breedInformation}
        getBreedInformation={getBreedInformation}
        isAdvancedSearch={isAdvancedSearch}
        isFiltered={isFiltered}
        isMobile={isMobile}
        loaded={loaded}
        loading={loading}
        location={location}
        query={searchValues}
        randomTips={randomTips}
        results={results}
        total={total}
        handlePaginate={values => setSearchValues(values)}
        handleSaveSearch={() => {
          doAfterLogin(() => {
            setIsModalOpen(true);
          });
        }}
        handleSort={value => {
          const selectedOption = SORT_OPTIONS.find(option => option.value === value);
          setSearchValues({
            ...selectedOption.query,
            page: 0,
            sort: value,
          });
        }}
        handleToggleFeatured={() => {
          setSearchValues({ page: 0, premiers_only: searchValues.premiers_only === 'true' ? 'false' : 'true' });
        }}
        setMobilePaneOpen={() => setIsMobilePaneOpen(true)}
      />
      {isMobile && (
        <MobileSortFilterPane
          isOpen={isMobilePaneOpen}
          query={searchValues}
          sortOptions={getSortOptions(searchValues)}
          onClose={() => setIsMobilePaneOpen(false)}
          onReset={() => {
            setSearchValues(getInitialValues({ isMobile, location, match }));
          }}
          onSubmit={values => {
            const selectedOption = SORT_OPTIONS.find(option => option.value === values.sort);
            setSearchValues({
              ...values,
              ...selectedOption.query,
              page: 0,
            });
          }}
        />
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeOnEscape closeOnOutsideClick>
        <SaveSearchForm
          closeModal={() => setIsModalOpen(false)}
          isBreedMatchmaker={searchValues.show_matchmaker}
          submitFunction={values => {
            return addSavedSearch({
              ...values,
              breed: searchValues.breed || values.breed,
              formatted_breed: searchValues.formatted_breed || values.formatted_breed,
              city: searchValues.city || values.city,
              state: searchValues.state || values.state,
            });
          }}
        />
      </Modal>
      {user && !user.get('phone_number_verified') ? (
        <PhoneVerificationModal history={history} to="/find-a-puppy" />
      ) : null}
    </div>
  );
};

SearchListings.fetchData = (location, match, user) => {
  const actions = [getRandomTips(), searchListings(buildInitialQuery(match))];

  return actions;
};

export default SearchListings;
