import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { NavLink, Link, withRouter } from 'react-router-dom';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonPlain, StartTrialModal } from '../';
import MobileMenu from './MobileMenu';
import { IconMenu, ErrorBar } from '../';
import Icon from '../icons/icon';
import theme from '../../../theme';

class TopNav extends Component {
  state = { showTrialModal: false };

  userInformationLinks = userVM => {
    const isMember = userVM.get('isMember');

    return [
      {
        name: 'Edit Profile',
        to: '/account/my-profile',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'My Listings',
        to: '/listings',
        onMobile: false,
        enabled: isMember,
      },
      {
        name: 'Message Center',
        to: '/messages',
        onMobile: false,
        tip: 'messages-tip',
        enabled: true,
      },
      {
        name: 'My Favorites',
        to: '/favorites',
        onMobile: false,
        enabled: true,
      },
      {
        name: 'Membership & Billing',
        to: '/account/membership',
        onMobile: true,
        enabled: isMember,
      },
      {
        name: 'My Testimonials',
        to: isMember ? '/feedback-manager/received' : '/feedback-manager/left',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Saved Searches',
        to: '/saved-searches',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Verifications',
        to: '/account/verifications',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Change Password',
        to: '/account/change-password',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Notification Settings',
        to: '/account/notification-settings',
        onMobile: true,
        enabled: true,
      },
    ];
  };

  handleTrialStart = () => {
    const { history, isLoggedIn } = this.props;
    this.setState({ showTrialModal: false });
    let destination = '';
    if (isLoggedIn) {
      destination = '/account/my-profile';
    } else {
      destination = '/sign-up';
    }
    history.push({ pathname: destination, state: { purchaseType: 'trial' } });
  };

  navLinks = () => {
    const { user, userVM } = this.props;
    const isMember = userVM.get('isMember');
    return [
      {
        name: 'Find A Puppy',
        to: '/find-a-puppy',
      },
      {
        name: 'Breed Matchmaker',
        to: '/breed-matchmaker',
      },
      {
        name: 'Blog',
        to: '/blog',
      },
      {
        name: 'Breeds',
        to: '/breeds',
      },
      {
        name: 'Store',
        to: 'https://puppies.com/store',
      },
      {
        name: 'FAQ',
        to: 'https://help.puppies.com',
      },
      {
        name: user && isMember ? 'My Listings' : 'List A Puppy',
        to: '/listings',
        onClick: user && isMember ? null : () => this.setState({ showTrialModal: true }),
      },
    ];
  };

  renderNavLinkIcons = size => {
    const { messageCount, styles } = this.props;
    return (
      <div className={styles.iconNavLinks}>
        <NavLink
          to={'/messages'}
          className={styles.iconNavLink}
          activeClassName={styles.iconNavLinkActive}
          id="messages-tip"
        >
          <Icon
            icon="Envelope"
            size={size}
            fill={theme.colors.brown}
            notifications={messageCount}
            label="unread messages"
          />
        </NavLink>
        <NavLink
          to={'/favorites'}
          className={styles.iconNavLink}
          activeClassName={styles.iconNavLinkActive}
          isActive={(match, location) => location.pathname.indexOf('/favorites') === 0}
          id="listings-tip"
        >
          <Icon icon="Heart" size={size} fill={theme.colors.brown} />
        </NavLink>
      </div>
    );
  };

  navLinksMobile = () => {
    const { user, messageCount, userVM } = this.props;
    const isNonMember = !userVM.get('isMember');
    let links = [
      {
        name: 'Find A Puppy',
        to: '/find-a-puppy',
      },
      {
        name: 'Breed Matchmaker',
        to: '/breed-matchmaker',
      },
      {
        name: user && !isNonMember ? 'My Listings' : 'List A Puppy',
        to: '/listings',
        onClick: user && !isNonMember ? null : () => this.setState({ showTrialModal: true }),
      },
      {
        name: (
          <span>
            Message Center{' '}
            {messageCount > 0 && (
              <span
                style={{
                  display: 'inline-block',
                  minWidth: '30px',
                  textAlign: 'center',
                  backgroundColor: theme.colors.darkerRed,
                  color: theme.colors.white,
                  borderRadius: '100%',
                  padding: '4px',
                }}
              >
                {messageCount}
              </span>
            )}
          </span>
        ),
        to: '/messages',
        tip: 'messages-tip',
      },
      {
        name: 'Store',
        to: 'https://puppies.com/store',
      },
      {
        name: 'My Favorites',
        to: '/favorites',
      },
      { name: 'expandableMenuSlot' },
      {
        name: 'Blog',
        to: '/blog',
      },
      {
        name: 'Breeds',
        to: '/breeds',
      },
      {
        name: 'FAQ',
        to: 'https://help.puppies.com',
      },
    ];

    if (user) {
      links.push({
        name: 'Sign Out',
        to: '/logout',
      });
    }
    return links;
  };

  renderUserInformation = () => {
    const { isLoggedIn, styles, userVM } = this.props;
    if (!isLoggedIn) {
      return (
        <div className={styles.userInfo}>
          <Link className={styles.userInfoLink} to={'/sign-up'}>
            <span>Sign Up</span>
          </Link>
          <Link className={styles.userInfoLink} to={'/sign-in'}>
            <span>Sign In</span>
          </Link>
        </div>
      );
    }

    return (
      <div className={styles.userInfo}>
        {this.renderNavLinkIcons(16.65)}
        <span id="main-dropdown-menu">
          <IconMenu icon={<Icon icon="Menu" fill={theme.colors.lightBrown} size={40} />}>
            {this.userInformationLinks(userVM).map((link, i) => {
              if (!link.enabled) {
                return null;
              }

              if (link.onClick) {
                return (
                  <div onClick={link.onClick} key={i} className={styles.userInfoLinkDiv}>
                    {link.name}
                  </div>
                );
              }

              return (
                <Link key={i} to={link.to}>
                  {link.name}
                </Link>
              );
            })}
          </IconMenu>
        </span>
      </div>
    );
  };

  renderDesktopNav() {
    const { styles, user } = this.props;

    return (
      <div
        className={styles.root + ' ' + styles.desktopRoot}
        style={user && user.get('impersonated_by') && { top: '56px' }}
      >
        <div className={styles.nav}>
          <NavLink to={'/'}>
            <div className={styles.navIcon}>
              <svg
                width="19px"
                height="28px"
                viewBox="0 0 19 28"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.navCrownIcon}
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g fill="#1AB9C4" fillRule="nonzero">
                    <path d="M3.693125,24.2717083 C3.6729375,23.6138333 3.8154375,22.8799583 3.9235,22.2113958 C4.34625,19.5549583 5.567,17.2025208 7.3945625,15.8202708 C6.5015625,15.3417083 5.8508125,14.3845833 5.3876875,12.9821458 C4.4875625,10.2651458 5.5040625,7.52558333 8.2258125,6.62902083 C10.723125,5.80370833 12.6551875,6.47108333 13.6526875,8.68695833 C13.796375,8.66558333 13.9495625,8.64895833 14.1134375,8.64064583 C14.1909906,8.39631557 14.3969988,8.21469291 14.6491219,8.1683713 C14.901245,8.12204962 15.1583582,8.21858482 15.3176947,8.41939218 C15.4770313,8.62019962 15.5126012,8.89252472 15.4101875,9.12752083 C16.3079375,10.1416458 17.0655625,12.6104583 13.7785625,15.0911458 C15.4909375,16.1100208 15.848375,18.5170833 17.515625,22.4251458 C18.9418125,25.7691458 19.6685625,26.3723958 18.1770625,26.3925833 C17.0394375,26.4080208 14.8354375,21.7102708 14.8354375,21.7102708 C15.002875,24.7015833 12.694375,24.9984583 12.694375,24.9984583 C16.302,24.9580833 15.7225,26.5659583 15.7225,26.5659583 C14.2028597,26.8350451 12.6602326,26.952393 11.117375,26.9162708 C9.3040625,26.8782708 6.9255,26.5873333 5.5420625,26.2583958 C3.9033125,26.3474583 0.5783125,26.0945208 0.6139375,22.5712083 C0.6139375,22.5712083 2.139875,24.5020833 3.693125,24.2717083" />
                    <path d="M7.62375,5.92958333 C5.8246875,6.52214583 4.7785,7.92577083 4.5398125,9.59183333 C4.4305625,9.71652083 4.31775,9.83764583 4.2120625,9.96470833 L0,6.49720833 C1.09349486,6.27540311 2.20382413,6.14669231 3.3190625,6.11245833 C3.3998125,5.03658333 3.574375,3.93933333 3.8486875,2.82902083 C4.96110913,2.87145609 6.06809298,3.00610536 7.15825,3.23158333 C7.488375,2.20558333 7.91825,1.17483333 8.4490625,0.158333333 L10.623375,5.15533333 C10.3063125,5.29308333 9.9928125,5.44033333 9.68525,5.59589583 C9.0535,5.57570833 8.36475,5.68495833 7.62375,5.92839583" />
                  </g>
                </g>
              </svg>
              <Icon icon="PuppyLogoTextSmaller" className={styles.puppyLogoText} />
            </div>
          </NavLink>
          <div className={styles.navRight}>
            <div className={styles.navLinks} data-testid="header-menu">
              {this.navLinks().map((link, i) => {
                if (link.onClick) {
                  return (
                    <div className={styles.navLink} key={i} onClick={link.onClick}>
                      {link.name}
                    </div>
                  );
                }

                if (link.to.indexOf('help.') >= 0) {
                  return (
                    <a href={link.to} target="_blank" key={i} rel="noreferrer" className={styles.navLink}>
                      {link.name}
                    </a>
                  );
                }
                if (link.to === 'https://puppies.com/store') {
                  return (
                    <a href={link.to} key={i} rel="noreferrer" className={styles.navLink}>
                      {link.name}
                    </a>
                  );
                }

                return (
                  <NavLink
                    activeClassName={styles.navLinkActive}
                    className={styles.navLink}
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }

                      const matchListingId = matchPath(location.pathname, {
                        path: '/listings/:listingId',
                        exact: true,
                        strict: false,
                      });

                      const listingId = matchListingId && matchListingId.params && matchListingId.params.listingId;

                      // Check whether user exists and user owns listing
                      if (listingId && user) {
                        if (user.get('listings').find(listing => listing.get('id') === listingId)) {
                          return true;
                        } else {
                          return false;
                        }
                      }

                      return true;
                    }}
                    key={i}
                    to={link.to}
                    id={link.tip}
                  >
                    {link.name}
                  </NavLink>
                );
              })}
            </div>
            {this.renderUserInformation()}
          </div>
        </div>
      </div>
    );
  }

  renderMobileNav() {
    const { styles, isLoggedIn, messageCount, userVM } = this.props;

    return (
      <div className={styles.root + ' ' + styles.mobileRoot}>
        <div className={styles.nav}>
          <NavLink to={'/'}>
            <div className={styles.navIcon}>
              <svg
                width="19px"
                height="28px"
                viewBox="0 0 19 28"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.navCrownIcon}
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g fill="#1AB9C4" fillRule="nonzero">
                    <path d="M3.693125,24.2717083 C3.6729375,23.6138333 3.8154375,22.8799583 3.9235,22.2113958 C4.34625,19.5549583 5.567,17.2025208 7.3945625,15.8202708 C6.5015625,15.3417083 5.8508125,14.3845833 5.3876875,12.9821458 C4.4875625,10.2651458 5.5040625,7.52558333 8.2258125,6.62902083 C10.723125,5.80370833 12.6551875,6.47108333 13.6526875,8.68695833 C13.796375,8.66558333 13.9495625,8.64895833 14.1134375,8.64064583 C14.1909906,8.39631557 14.3969988,8.21469291 14.6491219,8.1683713 C14.901245,8.12204962 15.1583582,8.21858482 15.3176947,8.41939218 C15.4770313,8.62019962 15.5126012,8.89252472 15.4101875,9.12752083 C16.3079375,10.1416458 17.0655625,12.6104583 13.7785625,15.0911458 C15.4909375,16.1100208 15.848375,18.5170833 17.515625,22.4251458 C18.9418125,25.7691458 19.6685625,26.3723958 18.1770625,26.3925833 C17.0394375,26.4080208 14.8354375,21.7102708 14.8354375,21.7102708 C15.002875,24.7015833 12.694375,24.9984583 12.694375,24.9984583 C16.302,24.9580833 15.7225,26.5659583 15.7225,26.5659583 C14.2028597,26.8350451 12.6602326,26.952393 11.117375,26.9162708 C9.3040625,26.8782708 6.9255,26.5873333 5.5420625,26.2583958 C3.9033125,26.3474583 0.5783125,26.0945208 0.6139375,22.5712083 C0.6139375,22.5712083 2.139875,24.5020833 3.693125,24.2717083" />
                    <path d="M7.62375,5.92958333 C5.8246875,6.52214583 4.7785,7.92577083 4.5398125,9.59183333 C4.4305625,9.71652083 4.31775,9.83764583 4.2120625,9.96470833 L0,6.49720833 C1.09349486,6.27540311 2.20382413,6.14669231 3.3190625,6.11245833 C3.3998125,5.03658333 3.574375,3.93933333 3.8486875,2.82902083 C4.96110913,2.87145609 6.06809298,3.00610536 7.15825,3.23158333 C7.488375,2.20558333 7.91825,1.17483333 8.4490625,0.158333333 L10.623375,5.15533333 C10.3063125,5.29308333 9.9928125,5.44033333 9.68525,5.59589583 C9.0535,5.57570833 8.36475,5.68495833 7.62375,5.92839583" />
                  </g>
                </g>
              </svg>
              <Icon icon="PuppyLogoTextSmaller" className={styles.puppyLogoText} />
            </div>
          </NavLink>
          <MobileMenu
            notifications={messageCount}
            isLoggedIn={isLoggedIn}
            showTrialModal={() => this.setState({ showTrialModal: true })}
            links={this.navLinksMobile()}
            expandedMenuLinks={isLoggedIn ? this.userInformationLinks(userVM) : null}
            expandedMenuTitle="Profile & Settings"
          />
        </div>
      </div>
    );
  }

  renderTrialModal = () => {
    const { history, userVM, isLoggedIn } = this.props;
    if (userVM.get('isTrial') && userVM.get('isExpired')) {
      return (
        <Modal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          closeOnEscape
          closeOnOutsideClick
        >
          <ModalHeader title="Trial Expired" />
          <ModalBody>
            Keep your listings active starting at only <strong>$29.99</strong> for 30 days of access, or{' '}
            <strong>$19.99</strong> monthly.
          </ModalBody>
          <ModalFooter
            actions={[
              <ButtonPlain
                type="button"
                variant="red"
                onClick={() => {
                  this.setState({ showTrialModal: false });
                }}
              >
                Cancel
              </ButtonPlain>,
              <Button
                type="button"
                buttonType="round"
                onClick={() =>
                  this.setState({ showTrialModal: false }, () => {
                    history.push('/account/membership');
                  })
                }
              >
                Upgrade Now
              </Button>,
            ]}
          />
        </Modal>
      );
    } else if (userVM.get('isEligibleForTrial') || !isLoggedIn) {
      return (
        <StartTrialModal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          onSubmit={this.handleTrialStart}
          closeOnEscape
          closeOnOutsideClick
        />
      );
    }
    return null;
  };

  render() {
    const { styles, errorBarError } = this.props;
    return (
      <div className={styles.topNavContainer}>
        {this.renderMobileNav()}
        {this.renderDesktopNav()}
        {this.renderTrialModal()}
        <ErrorBar message={errorBarError} />
      </div>
    );
  }
}

TopNav.defaultProps = {
  isSeller: false,
  isLoggedIn: false,
};

TopNav.propTypes = {
  isSeller: PropTypes.bool,
  messageCount: PropTypes.number,
  isLoggedIn: PropTypes.bool,
};

const styles = props => ({
  topNavContainer: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 5,
  },
  root: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '82px',
    width: '100%',
    backgroundColor: props.theme.colors.tan,
    borderBottom: `7px solid ${props.theme.colors.yellow}`,
  },
  desktopRoot: {
    display: 'none',
    [props.theme.breakpoints.desktop]: {
      display: 'flex',
    },
  },
  mobileRoot: {
    display: 'flex',
    [props.theme.breakpoints.desktop]: {
      display: 'none',
    },
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
  },
  navRight: {
    display: 'flex',
    alignItems: 'center',
  },
  navLinks: {
    display: 'flex',

    '> * + *': {
      marginLeft: '15px',
    },
  },
  navLink: {
    fontSize: '12px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltMedium,
    color: props.theme.colors.brown,
    letterSpacing: '1.8px',
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    padding: '4px 12px',
    borderRadius: '14px',
    transition: props.theme.globalTransition,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: `${props.theme.colors.blue} !important`,
      color: `${props.theme.colors.white} !important`,
    },
  },
  navLinkActive: {
    backgroundColor: `${props.theme.colors.blue} !important`,
    color: `${props.theme.colors.white} !important`,
  },
  iconNavLinks: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '6px',
    },
  },
  iconNavLink: {
    padding: '6px',
    cursor: 'pointer',
    borderRadius: '50%',
    ':hover': {
      backgroundColor: `${props.theme.colors.blue} !important`,
      '> span': {
        fill: `${props.theme.colors.white} !important`,
      },
    },
  },
  iconNavLinkActive: {
    backgroundColor: `${props.theme.colors.blue} !important`,
    '> span': {
      fill: `${props.theme.colors.white} !important`,
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '40px',
    '> * + *': {
      marginLeft: '12px',
    },
  },
  userInfoLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: props.theme.colors.blue,
    letterSpacing: '1.25px',
    cursor: 'pointer',
    fontWeight: props.theme.typography.sansBold,
    '> svg': {
      marginRight: '4px',
    },
    '> * + *': {
      marginLeft: '4px',
    },
    ':hover': {
      color: props.theme.colors.darkerBlue,
    },
  },
  userInfoLinkDiv: {
    display: 'flex',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
    cursor: 'pointer',
    padding: '16px 20px',
    '> svg': {
      marginRight: '4px',
    },
    '> * + *': {
      marginLeft: '4px',
    },
    ':hover': {
      backgroundColor: props.theme.colors.darkerBlue,
    },
  },
  icon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navIcon: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  navCrownIcon: {
    marginRight: '5px',
  },
  puppyLogoText: {
    width: '125px !important',
    height: '20px !important',
  },
  iconMenu: {
    padding: '20px',
    fontSize: '12px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
  },
  mobileMenuExpand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0',
    fontSize: '24px',
    color: props.theme.typography.bodyColor,
    textTransform: 'uppercase',
    fontFamily: props.theme.typography.sansAlt,
    cursor: 'pointer',
    '> * + *': {
      marginLeft: '10px',
    },
  },
  messageCount: {
    backgroundColor: props.theme.colors.darkerRed,
    color: props.theme.colors.white,
    borderRadius: '100%',
  },
});

export default withRouter(felaConnect(styles)(TopNav));
